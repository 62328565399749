Main Css::selection {
    /* background: #fff; */
    color: #fff;
    text-shadow: none;
}

::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
    display: none;
}

::-webkit-scrollbar-track-piece {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #dedede;
}

::-webkit-scrollbar-thumb:vertical {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #9b5fc2;
}

/*Loader Css*/
.loader {
    position: fixed;
    left: 0;
    top: 0;
    background: white;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    animation: backgroundChange .5s linear 5s forwards;
    z-index: 9999;
}

.load-text {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    font-size: 10vmax;
    color: #000;
    font-weight: 800;
    width: fit-content;
    display: flex;
    animation: colorChange .5s linear 5s forwards;
}

.loaded-text {
    transform: scale(1.5);
    animation: scaleText .5s linear .5s forwards;
}

.loading-text {
    width: 0;
    overflow: hidden;
    animation: expand .5s linear 1.5s forwards;
}

main {
    padding: 1em;
    box-sizing: border-box;
    background-color: black;
    color: white;
    min-height: 100vh;
    /* font-family: 'Open Sans', sans-serif; */
}

@keyframes scaleText {
    from {
        transform: scale(1.5)
    }

    to {
        transform: scale(1)
    }
}

@keyframes expand {
    from {
        width: 0;
    }

    to {
        width: 40vmax;
    }
}

@keyframes backgroundChange {
    from {
        background-color: white
    }

    to {
        background-color: black
    }
}

@keyframes colorChange {
    from {
        color: black
    }

    to {
        color: white
    }
}

@keyframes fadeOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        z-index: -1;
    }
}

.before-none::before {
    display: none !important;
}

body {
    /* font-family: 'Inter', sans-serif !important; */
    overflow-x: hidden;
    font-size: 16px;
    line-height: 1;
    /* color: #000000; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
    display: block;
    font-weight: normal;
}

*:hover,
*:focus,
* {
    outline: none !important;
}

img {
    max-width: 100%;
    height: auto;
}

a,
input[type="submit"] {
    -webkit-transition: all 0.4s ease-In-out;
    -moz-transition: all 0.4s ease-In-out;
    -o-transition: all 0.4s ease-In-out;
    transition: all 0.4s ease-In-out;
    display: inline-block;

}

a:hover {
    color: #fff;
    text-decoration: none;
}

p,
a {
    font-size: 16px;
    font-weight: normal;
    /* color: #000000; */
}

p {
    line-height: 26px;
    display: block;
}

span {
    display: inline-block;
}




textarea,
select,
input[type],
textarea,
select,
button {
    background: transparent;
    border: none;
    border-radius: 0px;
    /* font-family: 'Inter', serif; */
    font-weight: 400;
}

::-webkit-input-placeholder {
    color: #a3a3a3;
    font-weight: 400;
}

::-moz-placeholder {
    color: #a3a3a3;
    font-weight: 400;
}

:-ms-input-placeholder {
    color: #a3a3a3;
    font-weight: 400;
}

:-moz-placeholder {
    color: #a3a3a3;
    font-weight: 400;
}

/* Tabbing CSS */
[class^="box-"] {
    display: none
}

[class^="box-"].showfirst {
    display: block
}

/* Accordion CSS */
.myaccordion li .faq-opt {
    display: none;
}

.myaccordion li.active .faq-opt {
    display: block;
}

.myaccordion li.active .faq-ang h4:before {
    content: '\f077';
}

/* Custom Slick Css */
.slick-list {
    margin: 0 -15px;
}

.slick-slide {
    margin: 0;
    padding: 30px;
}

ul.slick-dots {
    padding: 50px 0 0;
    text-align: center;
}

ul.slick-dots li {
    margin: 0 20px 0 0px;
    width: auto;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    padding: 0px;
    border: none;
}

.slick-dots li button:before,
.slick-dots li button:before {
    color: #FFF;
    opacity: 1;
    font-size: 20px;
}

.slick-dots li button {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    padding: 0px;
    background: #d6d6d6;
    border: none;

    font-size: 0px;
    padding: 0px;
    -webkit-transition: all 0.4s ease-In-out;
    -moz-transition: all 0.4s ease-In-out;
    -o-transition: all 0.4s ease-In-out;
    transition: all 0.4s ease-In-out;
    box-sizing: border-box;
}

.slick-dots li.slick-active button {
    background: #f3c919;
    width: 11px;
    height: 11px;
}

span.arrows {
    width: 40px;
    height: 40px;
    border: none;
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    z-index: 2;

    line-height: 39px;
    font-size: 12px;
    text-align: center;
    font-weight: 300;
    top: 0;
    border-radius: 0;
    background: #f7f7f7;
    color: #696969
}

span.arrows.next {
    right: 1%;
}

/* Global Css */
.sprite-icn:before {
    content: "";
    /* background-image: url(../images/sprite.png); */
    background-repeat: no-repeat;
    display: inline-block;
}

.ovr-hiddn {
    overflow: hidden;
}

.overlay:after,
.overlay2:after {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.60);
    z-index: 1;
}

.overlay,
.overlay2 {
    display: none;
}

.overlay.active,
.overlay2.active {
    display: block;
}

/* Padding Classes */
.pad-sec {}

.pad-zero {
    padding: 0px;
}

.pad-l-zero {
    padding-left: 0px;
}

.pad-r-zero {
    padding-right: 0px;
    padding: 0;
}

/* header CSS */
header {
    position: absolute;
    width: 100%;
    z-index: 1;
    -webkit-transition: all 0.4s ease-In-out;
    -moz-transition: all 0.4s ease-In-out;
    -o-transition: all 0.4s ease-In-out;
    transition: all 0.4s ease-In-out;
}

.header-top {
    border-bottom: 2px solid #000000;
}

.headerwrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.headnumb {
    /* margin-right: 30px; */
}

.headnumb a {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    line-height: normal;
}

.headbtns {
    font-size: 0;
    margin: 0;
}

.headbtns li {
    display: inline-block;
    vertical-align: middle;
}

.headbtns li a {
    font-size: 16px;
    height: 55px;
    text-align: center;
    color: #fff;
    display: flex;
    width: 250px;
    background-color: #000;
    font-weight: 500;
    align-items: center;
    justify-content: center;
}

.headbtns li.last a {
    background-color: #9b5fc2;
}

.headbtns li a:hover {
    background-color: #fff;
    color: black;
    font-weight: bold;
}

.main-header {
    padding: 55px 0;
    padding-left: 100px;
    padding-right: 200px;
}

.logo {
    display: inline-block;
}

.logo img {
    display: block;
}

/* Menu Css */
.menu {
    font-size: 0px;
    display: inline-block;
    vertical-align: middle;
}

.menu>li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 30px;
    padding: 10px 0
}

.menu>li a {
    display: block;
    font-size: 18px;
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
}

.menu>li:hover a,
.menu>li a.active {
    color: #9b5fc2;
}

.menu>li.last {
    width: 60px;
    height: 45px;
}

/* Dropdown CSS*/
@keyframes btotreverse {
    0% {
        top: 75px;
        opacity: 1;
    }

    100% {
        top: 105px;
        opacity: 0;
    }
}

@keyframes btot {
    0% {
        top: 105px;
        opacity: 0;
    }

    100% {
        top: 35px;
        opacity: 1;
    }
}

.dropdown-nav {
    position: relative;
    overflow: hidden;
    height: 100%;
    vertical-align: middle;
}

.dropdown-nav:hover {
    overflow: visible;
}

ul.dropdown {
    position: absolute;
    width: 300px;
    left: -0px;
    margin: auto;
    background: rgba(34, 34, 34, 1);
    text-align: left;
    border-radius: 2px;
    box-shadow: 0 0 10px 3px #00000014;
    opacity: 0;
    animation: btotreverse 0.5s forwards;
}

ul.dropdown li {
    width: 100%;
    display: block;
}

ul.dropdown li a {
    position: relative;
    padding: 15px 30px;
    font-size: 16px;
    line-height: normal;
    color: #fff !important;
    display: block;
    width: 100%;
    font-weight: 300;
}

/*ul.dropdown li.last a{border-bottom:none;}*/
ul.dropdown li a:hover {
    color: #fa426a !important;
}

.dropdown-nav:hover ul.dropdown {
    animation: btot 0.5s forwards;
    z-index: 9;
}

.dropdown-nav:hover {
    overflow: visible;
}

/* Dropdown Sub Menu */
@keyframes btotreverse2 {
    0% {
        left: 300px;
        opacity: 1;
    }

    100% {
        left: 200px;
        opacity: 0;
    }
}

@keyframes btot2 {
    0% {
        left: 200px;
        opacity: 0;
    }

    100% {
        left: 300px;
        opacity: 1;
    }
}

.sub-menu {
    position: relative;
}

.sub-menu-list {
    position: absolute;
    width: 314px;
    top: 0;
    left: -0px;
    margin: auto;
    background: rgba(34, 34, 34, 1);
    text-align: left;
    border-radius: 2px;
    box-shadow: 0 0 10px 3px #00000014;
    opacity: 0;
    animation: btotreverse2 0.5s forwards;
}

.sub-menu-list li {
    width: 100%;
    display: block;
}

.sub-menu-list li a {
    position: relative;
    padding: 15px 30px;
    font-size: 16px;
    color: #fff !important;
    display: block;
    width: 100%;
    font-weight: 300;
}

.sub-menu-list li.last a {
    border-bottom: none;
}

.sub-menu-list li a:hover {
    color: #fa426a !important;
}

.sub-menu:hover ul.sub-menu-list {
    animation: btot2 0.5s forwards;
    z-index: 9;
}

ul.sub-menu ul.sub-menu-list li.last a {
    border-bottom: 1px solid #fa426a !important;
}

/* Custom CSS styling starts from here */
.mainBanner {
    background-size: cover;
    background-position: center;
    height: 100vh;
    padding: 0 250px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    position: relative;
    background-position: bottom;
}

.mainBanner h6 {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    padding-bottom: 10px;
}

.mainBanner h1 {
    font-size: 65px;
    font-weight: 700;
    color: #fff;
    line-height: 70px;
    letter-spacing: -2px;
}

.mainBanner h1 strong {
    color: #fff;
}

.mainBanner h1 span {
    color: #fa426a;
}

.mainBanner img {
    mix-blend-mode: difference;
}

.homescroll {
    display: block;
    font-size: 20px;
    font-weight: 500;
    color: #a6a6a6;
    line-height: normal;
    position: absolute;
    bottom: 65px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}

.homescroll span {
    display: block;
    height: 76px;
    width: 7px;
    background-color: #fff;
    text-align: center;
    margin: auto;
    margin-bottom: 20px;
}

.typed-cursor {
    opacity: 0;
    display: none;
    font-size: 75px;
    color: #fff;
}

.hmsec1 {
    position: relative;
    padding: 50px 0;
    z-index: 1;
    padding-left: 250px;
}

.hmsec1:before {
    content: '';
    position: absolute;
    height: 611px;
    width: 748px;
    /* background-image: url(../images/hmsec1-before.png); */
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    bottom: 0;
}

.hmsec1 span {
    display: block;
    width: 0;
    height: 0;
    border-bottom: 45px solid #9b5fc2;
    border-left: 36px solid transparent;
    margin-bottom: 20px;
}

.hmsec1 h6 {
    font-size: 20px;
    font-weight: 500;
    color: #8c8c8c;
    line-height: normal;
    padding-bottom: 10px;
}

.hmsec1 h4 {
    font-size: 40px;
    font-weight: 700;
    color: #101010;
    line-height: 50px;
    padding-bottom: 30px;
    letter-spacing: -2px;
}

.hmsec1 h4 strong {
    color: #fa426a;
}

.hmsec1 p {
    font-size: 18px;
    font-weight: 500;
    color: #adabab;
    line-height: 28px;
    padding-bottom: 30px;
}

.hmsec1 p:last-child {
    padding-bottom: 30px;
}

.hmsec1 a {
    width: 250px;
    height: 70px;
    background-color: #9b5fc2;
    text-align: center;
    line-height: 70px;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
}

.hmsec1 a:hover {
    background-color: #9b5fc2;
}

.hmsec1img {
    text-align: right;
}

.hmsec2 {
    position: relative;
    padding: 150px 200px;
    /* background-image: url(../images/bg-do.jpg); */
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom center;
}

/* .hmsec2:after {
    content: '';
    position: absolute;
    left: 230px;
    bottom: -150px;
    width: 0;
    height: 0;
    border-bottom: 80px solid #9b5fc2;
    border-right: 80px solid transparent;
} */

.hmsec2 span {
    display: block;
    width: 0;
    height: 0;
    border-bottom: 45px solid #9b5fc2;
    border-left: 36px solid transparent;
    margin-bottom: 20px;
}

.hmsec2 h6 {
    font-size: 20px;
    font-weight: 500;
    color: #2997cc;
    line-height: normal;
    padding-bottom: 10px;
}

.hmsec2 h4 {
    font-size: 50px;
    font-weight: 700;
    color: #9b5fc2;
    line-height: 50px;
    padding-bottom: 30px;
    letter-spacing: -2px;
    text-shadow: 0 1px white;
}

.hmsec2 h4 strong {
    color: #fa426a;
}

.hmsec2 p {
    font-size: 18px;
    font-weight: 500;
    color: #adabab;
    line-height: 28px;
    padding-bottom: 0;
}

.hmsecpad {
    padding-bottom: 50px;
}

.dowrap {
    padding: 50px 0px;
    transition: all 0.4s ease-In-out;
}

.dobox img {
    max-width: 50%;
}

/* .dowrap:hover .dobox {
    background-image: url(../images/hovereffect.png);
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.4s ease-In-out;
} */

/* .dowrap:hover .dobox img {
    filter: brightness(0) invert(1);
    transition: all 0.4s ease-In-out;
} */

.dobox {
    transition: all 0.4s ease-In-out;
    border-radius: 10px;
    background-color: #666565;
    box-shadow: 0px 0px 35px 0px rgb(154 154 154 / 30%);
    width: 100%;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.dowrap a {
    font-size: 24px;
    font-weight: 700;
    color: #b3b1b1;
    line-height: normal;
    display: block;
    position: relative;
    padding-bottom: 20px;
}

.dowrap a:before {
    content: '';
    position: absolute;
    height: 4px;
    width: 36px;
    background-color: #c4c4c4;
    left: 0;
    bottom: 8px;
}

.dowrap:hover a {
    color: #9b5fc2;
    transition: all 0.4s ease-In-out;
}

.doslider .slick-list {
    margin: 0;
}

.doslider .slick-arrow {
    z-index: 1;
    position: absolute;
    top: 0;
    font-size: 0;

}

.doslider .slick-arrow:after {
    content: 'Previous';
    position: absolute;
    font-size: 14px;
    color: #8c8c8c;
    line-height: normal;
    text-transform: uppercase;
    transition: all 0.4s ease-In-out;
}

.doslider .slick-prev.slick-arrow {
    right: 150px;
}

.doslider .slick-next.slick-arrow {
    right: 60px;
}

.doslider .slick-prev.slick-arrow:after {}

.doslider .slick-next.slick-arrow:after {
    content: 'Next';
}

.doslider .slick-arrow:hover:after {
    color: #fa426a;
    transition: all 0.4s ease-In-out;
}

.hmsec3 {
    padding: 200px 100px 80px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
}

.hmsec3 span {
    display: block;
    width: 0;
    height: 0;
    border-bottom: 45px solid #fa426a;
    border-left: 36px solid transparent;
    margin: auto;
    margin-bottom: 20px;
}

.hmsec3 h2 {
    font-size: 20px;
    font-weight: 500;
    color: #8c8c8c;
    text-transform: uppercase;
    padding-bottom: 15px;
}

.hmsec3 h4 {
    font-size: 45px;
    font-weight: 700;
    color: #fff;
    line-height: normal;
    padding-bottom: 50px;
}

.hmsec3 h4 strong {
    color: #9b5fc2;
}

.projectswrap {
    text-align: left;
    padding: 0 100px;
}

.procounts {
    font-size: 200px;
    color: #fa426a;
    letter-spacing: -15px;
    font-weight: 200;
    height: 150px;
    overflow: hidden;
    line-height: 200px;
}

.projectswrap h5 {
    font-size: 28px;
    font-weight: 700;
    color: #fff;
    line-height: normal;
    padding: 10px 0 30px;
    position: relative;
}

.projectswrap p {
    font-size: 14px;
    color: #adabab;
    font-weight: 400;
    line-height: 30px;
    padding-bottom: 50px;
}

.sliderwrap {
    width: auto;
    height: 730px;
    border-radius: 10px;
    margin: auto;
    margin-right: 0;
    overflow: hidden;
    border-radius: 15px;
}

.sliderwrap img {
    border-radius: 5px;
    height: 100%;
    width: 100%;
}

.slider-nav .slick-slide {
    margin: 0;
}

.slider-nav .slick-dots {
    padding: 80px 0 0;
}

.slider-nav .slick-dots li {
    margin: 0 10px 0 0px;
}

.slider-nav .slick-dots li button {
    height: 14px;
    width: 14px;
    background: transparent;
    border: 4px solid #8efffe;
}

.slider-nav .slick-dots li.slick-active button {
    width: 11px;
    height: 11px;
    background-color: #8efffe;
}

.hmsec4 {
    padding: 150px 200px 100px;
    padding-right: 0;
    position: relative;
}

.hmsec4:before {
    content: '';
    position: absolute;
    height: 374px;
    width: 330px;
    /* background-image: url(../images/dots.png); */
    background-repeat: no-repeat;
    background-size: cover;
    right: 150px;
    top: 100px;
}

.hmsec4 span.hmsec4shape {
    display: block;
    width: 0;
    height: 0;
    border-bottom: 45px solid #fa426a;
    border-left: 36px solid transparent;
    margin-bottom: 20px;
}

.hmsec4 h4 {
    font-size: 35px;
    font-weight: 800;
    color: #000;
    line-height: normal;
    padding-bottom: 50px;
}

ul.clientlist {
    font-size: 0;
}

ul.clientlist li {
    display: inline-block;
    margin-right: 10px;
    padding: 20px 0 10px;
    padding-right: 20px;
    vertical-align: top;
    height: 100px;
    display: inline-flex !important;
    align-items: center !important;
}

ul.clientlist li a img {
    transition: all 0.4s ease-In-out;
    max-width: 80%;
}

ul.clientlist li a:hover img {
    filter: brightness(0);
    transition: all 0.4s ease-In-out;
}

.clientwrap {
    background-color: #9b5fc2;
    padding: 80px;
    box-shadow: 0px 0px 32px 0px rgba(216, 216, 216, 0.6);
    width: 100%;
    height: 527px;
    position: relative;
    margin: auto;
    margin-right: 0;
    margin-bottom: 50px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    z-index: 1;
}

.clientwrap:before {
    content: '';
    position: absolute;
    height: 547px;
    width: 690px;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    /* background-image: url(../images/clientmap.png); */
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

.clientwrap:after {
    content: '';
    position: absolute;
    top: -30px;
    left: 100px;
    display: block;
    width: 0;
    height: 0;
    border-bottom: 46px solid #000;
    border-right: 46px solid transparent;
    margin-bottom: 20px;
}

.clientwrap h3 {
    font-size: 60px;
    font-weight: 700;
    color: #fff;
    line-height: normal;
    padding-bottom: 70px;
}

.clientwrap ul {
    font-size: 0;
    display: block;
}

.clientwrap ul li {
    display: inline-block;
    vertical-align: top;
    margin-right: 60px;
    text-align: center;
    position: relative;
    width: 28%;
}

.clientwrap ul li.last {
    margin-right: 0;
}

.clientwrap ul li.last span:before {
    display: none;
}

.clientwrap ul li span {
    font-size: 80px;
    font-weight: 800;
    letter-spacing: -5px;
    color: #000000;
    line-height: 80px;
    position: relative;
}

.clientwrap ul li span:before {
    content: '+';
    position: absolute;
    font-size: 80px;
    font-weight: 800;
    letter-spacing: -8px;
    color: #000000;
    line-height: 80px;
    right: -60px;
    /* left: 0; */
    top: 0;
    bottom: 0;
    margin: auto;
}

.clientwrap ul li h6 {
    font-size: 24px;
    font-weight: 400;
    color: #000000;
    line-height: normal;
    padding-top: 10px;
}

.clientdesc {
    padding: 0 100px;
    letter-spacing: -1px;
}

.clientdesc p {
    font-size: 18px;
    font-weight: 500;
    color: #adabab;
    line-height: 28px;
    padding-bottom: 30px;
}

.clientdesc ul {
    font-size: 0;
    display: block;
}

.clientdesc ul li {
    display: inline-block;
    vertical-align: top;
    margin-right: 25px;
}

.clientdesc ul li.last {
    margin-right: 0;
}

.clientdesc ul li a {
    width: 200px;
    height: 60px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    line-height: 60px;
    text-align: center;
    background-color: #fa426a;
    transition: all 0.4s ease-In-out;
}

.clientdesc ul li a:hover {
    background-color: #000;
    transition: all 0.4s ease-In-out;
}

.hmsec5 {
    padding: 150px;
    padding-left: 250px;
    position: relative;
    /* background-image: url(../images/bg-get.png); */
    background-repeat: no-repeat;
    background-size: cover;
}

.hmsec5wrp {
    padding-right: 50px;
}

.hmsec5 span {
    display: block;
    width: 0;
    height: 0;
    border-bottom: 45px solid #fa426a;
    border-left: 36px solid transparent;
    margin-bottom: 20px;
}

.hmsec5 h6 {
    font-size: 20px;
    font-weight: 500;
    color: #8c8c8c;
    line-height: normal;
    padding-bottom: 30px;
}

.hmsec5 h4 {
    font-size: 46px;
    font-weight: 700;
    color: #fff;
    letter-spacing: -2px;
    line-height: normal;
    padding-bottom: 30px;
}

.hmsec5 h4 strong {
    color: #9b5fc2;
}

.field {
    margin-bottom: 20px;
}

.field input,
.field select,
.field textarea {
    width: 100%;
    height: 60px;
    background-color: #fff;
    padding: 0 20px;
    font-size: 16px;
    font-weight: 400;
    color: #adabab;
}

.field textarea {
    height: 229px;
    padding: 25px 20px;
    resize: none;
}

.field input::placeholder,
.field textarea::placeholder {
    color: #adabab;
}

.field button {
    width: 220px;
    height: 65px;
    background-color: #9b5fc2;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    transition: all 0.4s ease-In-out;
}

.field button:hover {
    background-color: #fa426a;
    transition: all 0.4s ease-In-out;
}

.mapimg {
    text-align: right;
    padding-top: 150px;
}

.blackcon {
    background-color: #000;
    padding: 80px 50px 80px 150px;
    height: 550px;
}

.ftr-logo {
    margin-bottom: 30px;
}

.ftr-logo a {
    display: block;
}

.blackcon p,
.blackcon a {
    display: block;
    font-size: 18px;
    font-weight: 500;
    color: #adabab;
    line-height: normal;
    margin-bottom: 20px;
}

.blackcon p span {
    display: block;
}

.blackcon strong {
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    line-height: normal;
    padding-top: 20px;
}

.blackcon a:hover {
    color: #9b5fc2;
}

.widget h6 {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    line-height: normal;
    padding-bottom: 30px;
}

.quiklnks {
    font-size: 0;
    display: block;
}

.quiklnks li {
    margin-bottom: 20px;
}

.quiklnks li.last {
    margin-bottom: 0;
}

.quiklnks li a {
    display: block;
    font-size: 18px;
    font-weight: 500;
    color: #989898;
    line-height: normal;
}

.quiklnks li a:hover {
    color: #9b5fc2;
}

.ftr-grey {
    background-color: #1e1e1e;
    padding: 108.2px 60px;
    height: 500px;
}

.ftr-grey2 {
    background-color: #1e1e1e;
    padding: 100px 60px 160px;
    height: 500px;
}

.ftr-social {
    font-size: 0;
    display: block;
    padding-bottom: 50px;
}

.ftr-social li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
}

.ftr-social li.last {
    margin-right: 0;
}

.ftr-social li a {
    font-size: 25px;
    color: #fff;
}

.ftr-social li a:hover {
    color: #9b5fc2;
}

.ftr-grey2 input {
    width: 100%;
    height: 47px;
    background-color: #3c3c3c;
    padding: 0 15px;
    font-size: 14px;
    margin-bottom: 15px;
    color: #fff;
}

.ftr-grey2 button {
    background-color: #9b5fc2;
    width: 150px;
    height: 50px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    line-height: 50px;
    transition: all 0.4s ease-In-out;
}

.ftr-grey2 button:hover {
    background-color: #fff;
    transition: all 0.4s ease-In-out;
    color: black;
    font-weight: bold;
}

.ftr-reviews {
    background-color: #242424;
    padding: 100px 50px 240px;
    height: 500px;
}

.ftr-reviews p {
    font-size: 16px;
    font-weight: 400;
    color: #989898;
    line-height: 26px;
    padding-bottom: 20px;
    height: 220px;
    overflow: hidden;
    padding-bottom: 10px;
}

.ftr-reviews h6 {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    line-height: normal;
}

/* Inner Pages */
.innerBanner {
    height: 550px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 150px;
}

.innerBanner h6 {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    line-height: normal;
    padding-bottom: 20px;
}

.innerBanner h2 {
    font-size: 58px;
    font-weight: 700;
    color: #fff;
    line-height: 70px;
}

.innerBanner h2 strong {
    color: #fff;
}

.innerBanner img {
    mix-blend-mode: difference;
    max-width: 80%;
    margin: auto;
    display: block;
}

.abtsec1 {
    padding: 100px 0;
}

.abtsec1 h4 {
    font-size: 80px;
    font-weight: 800;
    color: #c56bff;
    line-height: 80px;
    letter-spacing: -4px;
}

.abtsec1 h4 strong {
    color: #9b5fc2;
}

.abtsec1 h4 span {
    color: #fff;
}

.abtsecpad1 {
    padding-left: 50px;
}

.abtsec1 p {
    font-size: 18px;
    font-weight: 400;
    color: #adabab;
    line-height: 28px;
    padding-bottom: 30px;
}

.abtsec1 a,
.servsec6 a {
    width: 200px;
    height: 65px;
    background-color: #9b5fc2;
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    line-height: 65px;
}

.abtsec1 a:hover,
.servsec6 a:hover {
    background-color: #fff;
    color: #000;
}

.abtsec2 {
    position: relative;
    /* padding-bottom: 150px; */
}

.abtsec2:before {
    content: '';
    position: absolute;
    height: 533px;
    width: 435px;
    /* background-image: url(../images/abtsectwobefore.png); */
    background-repeat: no-repeat;
    background-size: cover;
    right: 0;
    top: -250px;
    z-index: -1;
}

.abtsec2:after {
    content: '';
    position: absolute;
    height: 646px;
    width: 793px;
    /* background-image: url(../images/abtsectwoafter.png); */
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    bottom: 0;
    z-index: -1;
}

.abtsec2 h4 {
    font-size: 55px;
    font-weight: 800;
    color: #c56bff;
    padding-bottom: 30px;
    line-height: normal;
}

.abtsec2 h4 span {
    display: block;
    color: #fff;
}

.abtsec2 p {
    font-size: 18px;
    font-weight: 400;
    color: #adabab;
    line-height: 28px;
    padding-bottom: 30px;
}

.abtsec3 {
    position: relative;
    padding: 100px 150px;
}

.abtsec3:before {
    content: '';
    position: absolute;
    height: 374px;
    width: 330px;
    /* background-image: url(../images/dots.png); */
    background-repeat: no-repeat;
    background-size: cover;
    right: 50px;
    top: 0;
}

.abtsec3 h4 {
    font-size: 46px;
    font-weight: 800;
    color: #c56bff;
    padding-bottom: 30px;
    line-height: normal;
}

.abtsec3 p {
    font-size: 18px;
    font-weight: 400;
    color: #adabab;
    line-height: 28px;
    padding-bottom: 30px;
    padding-right: 100px;
}

.doslider2 {}

.doslider2 .slick-list {
    margin: 0;
}

.doslider2 .slick-arrow {
    z-index: 1;
    position: absolute;
    top: 0;
    font-size: 0;

}

.doslider2 .slick-arrow:after {
    content: 'Previous';
    position: absolute;
    font-size: 14px;
    color: #8c8c8c;
    line-height: normal;
    text-transform: uppercase;
    transition: all 0.4s ease-In-out;
}

.doslider2 .slick-prev.slick-arrow {
    right: 150px;
}

.doslider2 .slick-next.slick-arrow {
    right: 60px;
}

.doslider2 .slick-prev.slick-arrow:after {}

.doslider2 .slick-next.slick-arrow:after {
    content: 'Next';
}

.doslider2 .slick-arrow:hover:after {
    color: #fa426a;
    transition: all 0.4s ease-In-out;
}

.team {
    padding: 100px 150px 200px;
    padding-right: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
}

.team h4 {
    font-size: 55px;
    font-weight: 700;
    color: #fff;
    line-height: normal;
    padding-bottom: 30px;
}

.team h4 span {
    color: #fff;
    display: block;
}

.team p {
    font-size: 18px;
    font-weight: 400;
    color: #939393;
    line-height: 30px;
}

.teamimg {
    position: relative;
    transition: all 0.4s ease-In-out;
    display: inline-block;
}

.teamimg img {
    height: auto;
    width: auto;
    border-radius: 10px;
    transition: all 0.4s ease-In-out;
}

.teamcon {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 100%;
    width: auto;
    /* background-image: url(../images/teamimgover.png); */
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px;
    display: flex;
    align-items: flex-end;
    transition: all 0.4s ease-In-out;
}

.teamcon span {
    width: 0;
    height: 0;
    border-bottom: 30px solid #fff;
    border-left: 26px solid transparent;
}

.teamcon h5 {
    font-size: 21px;
    font-weight: 700;
    color: #fff;
    line-height: normal;
    padding-top: 15px;
}

.teamcon h6 {
    font-size: 21px;
    font-weight: 400;
    color: #fff;
    line-height: normal;
}

.teamimg:hover .teamcon {
    opacity: 1;
    transition: all 0.4s ease-In-out;
}

.teamimg:hover img {
    filter: grayscale(100%);
    transition: all 0.4s ease-In-out;
}

.teamslider .slick-arrow {
    z-index: 1;
    position: absolute;
    bottom: -80px;
    font-size: 0;

}

.teamslider .slick-arrow:after {
    content: 'Previous';
    position: absolute;
    font-size: 14px;
    color: #8c8c8c;
    line-height: normal;
    text-transform: uppercase;
    transition: all 0.4s ease-In-out;
}

.teamslider .slick-prev.slick-arrow {
    right: 150px;
}

.teamslider .slick-next.slick-arrow {
    right: 60px;
}

.teamslider .slick-prev.slick-arrow:after {}

.teamslider .slick-next.slick-arrow:after {
    content: 'Next';
}

.teamslider .slick-arrow:hover:after {
    color: #fa426a;
    transition: all 0.4s ease-In-out;
}

.servsec1 {
    padding: 100px 0 0;
    position: relative;
}

.servsec1:before {
    content: '';
    position: absolute;
    height: 374px;
    width: 330px;
    /* background-image: url(../images/dots.png); */
    background-repeat: no-repeat;
    background-size: cover;
    left: -100px;
    bottom: -100px;
    z-index: -1;
}

.servsec1 h4 {
    position: relative;
    font-size: 51px;
    line-height: 55px;
    font-weight: 800;
    color: #9b5fc2;
    letter-spacing: -3px;
    padding-left: 50px;
}

.servsec1 h4 span {
    color: #fff;
}

/* .servsec1 h4:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 45px solid #fff;
    border-left: 40px solid transparent;
    bottom: 0;
    left: -80px;
} */

.servsec1 p {
    font-size: 18px;
    font-weight: 400;
    color: #adabab;
    line-height: 28px;
    padding-left: 50px;
}

.servsecshap {
    width: 0;
    height: 0;
    border-top: 75px solid #d93458;
    border-left: 90px solid transparent;
    margin-top: 200px;
}

.servsec2 {
    padding: 100px 0 200px;
    padding-bottom: 0;
    padding-left: 200px;
    position: relative;
}

.servsec2:before {
    content: '';
    position: absolute;
    height: 1090px;
    width: 943px;
    z-index: -1;
    /* background-image: url(../images/sersecback.png); */
    background-repeat: no-repeat;
    background-size: cover;
    right: 0;
    bottom: 0;
}

.servsec2 h4 {
    font-size: 46px;
    font-weight: 800;
    color: #9b5fc2;
    line-height: normal;
    padding-bottom: 30px;
}

.servsec2 p {
    font-size: 18px;
    font-weight: 400;
    color: #939393;
    line-height: 28px;
    padding-bottom: 50px;
}

.servsec2 a {
    background-color: #9b5fc2;
    width: 200px;
    height: 60px;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    line-height: 60px;
}

.servsec2 a:hover {
    background-color: #fff;
    color: #000;
}

.slider-nav2 .slick-arrow {
    z-index: 1;
    position: absolute;
    bottom: -30px;
    font-size: 0;

}

.slider-nav2 .slick-arrow:after {
    content: 'Previous';
    position: absolute;
    font-size: 16px;
    color: #8c8c8c;
    line-height: normal;
    text-transform: uppercase;
    transition: all 0.4s ease-In-out;
}

.slider-nav2 .slick-prev.slick-arrow {
    right: 250px;
}

.slider-nav2 .slick-next.slick-arrow {
    right: 150px;
}

.slider-nav2 .slick-prev.slick-arrow:after {}

.slider-nav2 .slick-next.slick-arrow:after {
    content: 'Next';
}

.slider-nav2 .slick-arrow:hover:after {
    color: #fa426a;
    transition: all 0.4s ease-In-out;
}

.servsec3 {
    padding: 250px 200px 0;
    font-size: 0;
    position: relative;
    /* background-image: url(../images/bg-servsec3.png); */
    background-repeat: no-repeat;
    background-size: cover;
}

.servsec3:before {
    content: '';
    position: absolute;
    top: 150px;
    width: 0;
    height: 0;
    border-top: 75px solid #9b5fc2;
    border-left: 100px solid transparent;
}

.servsec3:after {
    content: '';
    position: absolute;
    bottom: -50px;
    right: 200px;
    width: 0;
    height: 0;
    border-bottom: 75px solid #9b5fc2;
    border-right: 100px solid transparent;
}

.servsec3 h4 {
    font-size: 50px;
    font-weight: 700;
    color: #fff;
    line-height: normal;
    padding-bottom: 10px;
    padding-top: 100px;
}

.servsec3 h3 {
    font-size: 24px;
    font-weight: 400;
    color: #f7194a;
    line-height: normal;
    padding-bottom: 50px;
}

.srvboxlist {
    font-size: 0;
    display: block;
}

.srvboxlist li {
    display: inline-block;
    vertical-align: top;
    margin-right: 30px;
    margin-bottom: 30px;
    width: 45%;
}

.srvboxlist li:nth-child(2n) {
    margin-right: 0;
}

.servsbx {
    border-radius: 10px;
    background-color: #fff;
    width: 100%;
    height: 298px;
    display: flex;
    align-items: flex-end;
    padding: 30px;
    position: relative;
    overflow: hidden;
    transition: all 0.4s ease-In-out;
}

.servsbx h5 {
    font-size: 32px;
    font-weight: 500;
    color: #9b5fc2;
    letter-spacing: -2px;
    padding-bottom: 5px;
}

.servsbx h6 {
    font-size: 29px;
    font-weight: 800;
    color: #000000;
    letter-spacing: -2px;
}

.servbxcon {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    padding: 30px;
    position: absolute;
    bottom: -298px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #9b5fc2;
    border-radius: 10px;
    transition: all 0.3s ease-In-out;
}

.servbxcon p {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    line-height: 26px;
}

.servsbx:hover .servbxcon {
    bottom: 0;
    transition: all 0.3s ease-In-out;
}

.servsec4 {
    position: relative;
    padding: 100px 0;
}

.servsec4:before {
    content: '';
    position: absolute;
    content: '';
    z-index: -1;
    position: absolute;
    height: 374px;
    width: 330px;
    /* background-image: url(../images/dots.png); */
    background-repeat: no-repeat;
    background-size: cover;
    left: 300px;
    top: 50px;
}

.servsec4:after {
    content: '';
    position: absolute;
    height: 1219px;
    width: 100%;
    /* background-image: url(../images/servicegrey.png); */
    background-repeat: no-repeat;
    background-size: cover;
    right: 0;
    top: 170px;
    z-index: -1;
}

.servinsec {
    padding: 0 250px;
    padding-bottom: 200px;
}

.servinsec h5 {
    position: relative;
    font-size: 46px;
    font-weight: 800;
    color: #151515;
    line-height: 55px;
    letter-spacing: -2px;
}

.servinsec h5:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -100px;
    width: 0;
    height: 0;
    border-top: 67px solid #9b5fc2;
    border-left: 80px solid transparent;
}

.servinsec p {
    font-size: 18px;
    font-weight: 400;
    color: #adabab;
    line-height: 28px;
    padding-bottom: 50px;
}

.servinsec a {
    width: 250px;
    height: 70px;
    background-color: #000;
    color: #fff;
    text-align: center;
    line-height: 70px;
    font-size: 18px;
    font-weight: 700;
}

.servinsec a:hover {
    background-color: #9b5fc2;
}

.servsec4 h4 {
    font-size: 45px;
    font-weight: 800;
    color: #000;
    text-align: center;
    padding-bottom: 50px;
    line-height: normal;
}

.pb-0 {
    padding-bottom: 0;
}

.clients-slider {
    font-size: 0;
}

.clients-slider li {
    margin: 0;
}

.clientsite {
    overflow: hidden;
    font-size: 0;
}

.clientsite img {
    height: 932px;
    width: 100%;

    transition: all 0.4s ease-In-out;
}

.clientsite:hover img {
    transform: scale(0.9);
    transition: all 0.4s ease-In-out;
}

.clients-slider .slick-arrow {
    z-index: 1;
    position: absolute;
    bottom: -30px;
    font-size: 0;

}

.clients-slider .slick-arrow:after {
    content: 'Previous';
    position: absolute;
    font-size: 16px;
    color: #8c8c8c;
    line-height: normal;
    text-transform: uppercase;
    transition: all 0.4s ease-In-out;
}

.clients-slider .slick-prev.slick-arrow {
    right: 250px;
}

.clients-slider .slick-next.slick-arrow {
    right: 150px;
}

.clients-slider .slick-prev.slick-arrow:after {}

.clients-slider .slick-next.slick-arrow:after {
    content: 'Next';
}

.clients-slider .slick-arrow:hover:after {
    color: #fa426a;
    transition: all 0.4s ease-In-out;
}

.servsec5 {
    padding: 100px 0;
    text-align: center;
    position: relative;
}

.servsec5:before {
    content: '';
    position: absolute;
    height: 368px;
    width: 631px;
    right: 100px;
    bottom: -100px;
    /* background-image: url(../images/tri-dots.png); */
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

.servsec5 span {
    width: 0;
    height: 0;
    border-bottom: 45px solid #fa426a;
    border-left: 40px solid transparent;
    margin-bottom: 20px;
}

.servsec5 h4 {
    font-size: 46px;
    font-weight: 800;
    color: #fa426a;
    line-height: normal;
    padding-bottom: 50px;
}

.servsec5 h4 strong {
    color: #000;
}

.servsec5 ul {
    font-size: 0;
    display: block;
}

.servsec5 ul li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
}

.servsec5 ul li.last {
    margin-right: 0;
}

.servsec5 ul li a {
    display: block;
    width: 300px;
    height: 75px;
    background-color: #000;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    line-height: 75px;
    color: #fff;
}

.servsec5 ul li a:hover {
    background-color: #9b5fc2;
}

.contact {
    padding: 100px 0;
    position: relative;
}

.contact:before {
    content: '';
    position: absolute;
    content: '';
    position: absolute;
    height: 374px;
    width: 330px;
    /* background-image: url(../images/dots.png); */
    background-repeat: no-repeat;
    background-size: cover;
    right: 100px;
    top: 100px;
}

.contact:after {
    content: '';
    position: absolute;
    height: 1050px;
    width: 100%;
    /* background-image: url(../images/contactsecbg.png); */
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    bottom: 0;
    z-index: -1;
}

.contactdescp {
    padding-bottom: 50px;
}

.contactdescp span {
    width: 0;
    height: 0;
    border-bottom: 40px solid #9b5fc2;
    border-left: 45px solid transparent;
    margin-bottom: 20px;
}

.contactdescp h6 {
    font-size: 18px;
    font-weight: 400;
    color: #8c8c8c;
    text-transform: uppercase;
    padding-bottom: 10px;
    line-height: normal;
}

.contactdescp h4 {
    font-size: 46px;
    font-weight: 800;
    color: #101010;
    line-height: 55px;
    letter-spacing: -2px;
}

.contactdescp h4 strong {
    color: #fa426a;
}

.contactdescp p {
    font-size: 18px;
    font-weight: 500;
    color: #8c8c8c;
    line-height: 28px;
    margin-top: 80px;
}

.contact form {
    position: relative;
}

.contact form:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 75px solid #d93458;
    border-left: 90px solid transparent;
    bottom: 30px;
    right: -50px;
}

.field2 {
    margin-bottom: 30px;
}

.field2 input,
.field2 textarea {
    width: 100%;
    height: 70px;
    box-shadow: 0px 0px 35px 0px rgba(68, 68, 68, 0.15);
    background-color: #fff;
    padding: 0 30px;
    font-size: 16px;
    font-weight: 500;
    color: #8c8c8c;
}

.field2 textarea {
    height: 250px;
    padding: 30px;
    resize: none;
}

.field2 button {
    background-color: #9b5fc2;
    width: 200px;
    height: 70px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    line-height: 70px;
    transition: all 0.4s ease-In-out;
}

.field2 button:hover {
    background-color: #000;
    transition: all 0.4s ease-In-out;
}

.mapwrap {
    font-size: 0;
}

.showsec1 {
    padding: 100px 0 200px;
    position: relative;
}

.showsec1:before {
    content: '';
    position: absolute;
    height: 374px;
    width: 330px;
    /* background-image: url(../images/dots.png); */
    background-repeat: no-repeat;
    background-size: cover;
    right: 100px;
    top: 100px;
    z-index: -1;
}

.showsec1:after {
    content: '';
    position: absolute;
    height: 368px;
    width: 631px;
    right: 100px;
    bottom: -100px;
    /* background-image: url(../images/tri-dots.png); */
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

.showsec1 .contactdescp p {
    margin: 0;
}

.showcase {
    padding: 0 150px;
}

.tablist {
    font-size: 0;
    display: block;
    text-align: center;
    padding-bottom: 50px;
}

.tablist li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 30px;
}

.tablist li.last {
    margin-right: 0;
}

.tablist li a {
    width: 280px;
    height: 70px;
    text-align: center;
    background-color: #626262;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 800;
    color: #fff;
    line-height: 70px;
}

.tablist li a:hover,
.tablist li.current a {
    background-color: #9b5fc2;
}

.tablist li.first a {
    background-color: transparent;
    border-radius: 0;
    width: auto;
    height: auto;
    color: #626262;
    padding: 20px;
}

.tablist li.first a:hover,
.tablist li.first.current a {
    color: #9b5fc2;
}

.shwimg {
    margin-bottom: 15px;
    border-radius: 5px;
}

.shwimg img {
    border-radius: 5px;
    transition: all 0.4s ease-In-out;
}

.shwimg a {
    overflow: hidden;
}

.shwimg a:hover img {
    transform: scale(1.2);
    transition: all 0.4s ease-In-out;
}

.showcase-slider .slick-arrow {
    z-index: 1;
    position: absolute;
    bottom: -30px;
    font-size: 0;

}

.showcase-slider .slick-arrow:after {
    content: 'Previous';
    position: absolute;
    font-size: 16px;
    color: #8c8c8c;
    line-height: normal;
    text-transform: uppercase;
    transition: all 0.4s ease-In-out;
}

.showcase-slider .slick-prev.slick-arrow {
    right: 150px;
}

.showcase-slider .slick-next.slick-arrow {
    right: 50px;
}

.showcase-slider .slick-prev.slick-arrow:after {}

.showcase-slider .slick-next.slick-arrow:after {
    content: 'Next';
}

.showcase-slider .slick-arrow:hover:after {
    color: #fa426a;
    transition: all 0.4s ease-In-out;
}

.careersec1 {
    padding: 100px 0 0;
    position: relative;
}

.careersec1:before {
    content: '';
    position: absolute;
    height: 325px;
    width: 325px;
    /* background-image: url(../images/csbe1.png); */
    background-repeat: no-repeat;
    background-size: cover;
    top: 100px;
    left: 20%;
}

.careersec1:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 75px solid #d93458;
    border-left: 90px solid transparent;
    right: 200px;
    bottom: 100px;
}

.careersec1 h4 {
    font-size: 55px;
    font-weight: 800;
    color: #101010;
    line-height: 65px;
}

.careersec1 h4 strong {
    color: #d93458;
}

.careersec1 p {
    font-size: 18px;
    font-weight: 400;
    color: #adabab;
    line-height: 28px;
}

.careersec1 .container {
    padding-bottom: 130px;
    border-bottom: 2px solid #dededf;
}

.careersec2 {
    padding: 100px 0;
    position: relative;
}

.careersec2:before {
    content: '';
    position: absolute;
    height: 1049px;
    width: 100%;
    z-index: -1;
    /* background-image: url(../images/careersec2.png); */
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    top: -250px;
}

.careersec2 h4 {
    font-size: 46px;
    font-weight: 800;
    color: #101010;
    line-height: normal;
    padding-bottom: 50px;
}

.carbox {
    width: 100%;
    height: 350px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 43px 0px rgba(175, 175, 175, 0.32);
    background-color: #fff;
    padding: 50px;
    position: relative;
    display: flex;
    align-items: flex-end;
    transition: all 0.4s ease-In-out;
}

.carbox img {
    position: absolute;
    left: 50px;
    top: 50px;
    max-width: 25%;
    transition: all 0.4s ease-In-out;
}

.carbox h6 {
    font-size: 26px;
    font-weight: 700;
    color: #9b5fc2;
    transition: all 0.4s ease-In-out;
}

.careersec2 a {
    display: block;
    font-size: 20px;
    font-weight: 400;
    color: #adabab;
}

.careersec2 a:hover {
    color: #d93458;
}

.carbox:hover {
    background-color: #d93458;
    transition: all 0.4s ease-In-out;
}

.carbox:hover img {
    filter: brightness(0) invert(1);
    transition: all 0.4s ease-In-out;
}

.carbox:hover h6 {
    color: #fff;
    transition: all 0.4s ease-In-out;
}

.careersec3 {
    height: 355px;
    width: 100%;
    display: flex;
    align-items: center;
    /* background-image: url(../images/bgcarer.jpg); */
    background-repeat: no-repeat;
    background-size: cover;
}

.careersec3 h4 {
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    line-height: normal;
}

.careersec3 .clientwrap {
    background: transparent;
    box-shadow: none;
}

.careersec3 .clientwrap:before {
    display: none;
}

.careersec3 .clientwrap:after {
    display: none;
}

.careersec3 .clientwrap ul li {
    width: 26%;
}

.careersec3 .clientwrap ul li h6 {
    font-size: 22px;
}

.careersec3 .clientwrap ul li span:before {
    font-size: 70px;
    line-height: 70px;
    right: -40px;
    top: 0px;
}

.careersec3 .clientwrap {
    padding: 0;
    height: auto;
    margin-bottom: 0;
}

.careersec3 .clientwrap ul li span {
    font-size: 70px;
}

.carslider .slick-arrow {
    z-index: 1;
    position: absolute;
    top: -90px;
    font-size: 0;

}

.carslider .slick-arrow:after {
    content: 'Previous';
    position: absolute;
    font-size: 16px;
    color: #8c8c8c;
    line-height: normal;
    text-transform: uppercase;
    transition: all 0.4s ease-In-out;
}

.carslider .slick-prev.slick-arrow {
    right: 150px;
}

.carslider .slick-next.slick-arrow {
    right: 50px;
}

.carslider .slick-prev.slick-arrow:after {}

.carslider .slick-next.slick-arrow:after {
    content: 'Next';
}

.carslider .slick-arrow:hover:after {
    color: #fa426a;
    transition: all 0.4s ease-In-out;
}

.clientsec {
    padding: 100px 0;
    position: relative;
}

.clientsec:before {
    content: '';
    position: absolute;
    height: 375px;
    width: 306px;
    z-index: -1;
    /* background-image: url(../images/clientbefore.png); */
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    top: 150px;
}

.clientsec:after {
    content: '';
    position: absolute;
    height: 325px;
    width: 325px;
    /* background-image: url(../images/csbe1.png); */
    background-repeat: no-repeat;
    background-size: cover;
    top: 150px;
    right: 70px;
    opacity: 0.5;
}

.clientsec p {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color: #adabab;
    padding-bottom: 100px;
}

.testimonial {
    height: 720px;
    padding-top: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-image: url(../images/bg-testi.png); */
    background-repeat: no-repeat;
    background-size: cover;
}

.testimonial .container {
    position: relative;
}

.testimonial .container:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 80px solid #9b5fc2;
    border-left: 100px solid transparent;
    left: 0;
    top: -120px;
}

.testimonial h4 {
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    line-height: normal;
    padding-bottom: 30px;
}

.testimonial h4 strong {
    color: #0e0e0e;
}

.testibox {
    border-radius: 10px;
    background-color: #fff;
    padding: 50px;
    padding-right: 100px;
    position: relative;
}

.testibox:before {
    content: '';
    position: absolute;
    height: 47px;
    width: 58px;
    /* background-image: url(../images/qoute.png); */
    background-repeat: no-repeat;
    background-size: cover;
    right: 30px;
    top: 30px;
}

.testibox h5 {
    font-size: 20px;
    font-weight: 700;
    color: #1d1d1d;
    padding-bottom: 20px;
    line-height: normal;
}

.testibox img {
    border: 3px solid #fff;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-box-shadow: 2px 4px 15px 0 rgba(212, 218, 220, .9) !important;
    box-shadow: 2px 4px 15px 0 rgba(212, 218, 220, .9) !important;
    height: 75px;
    width: 75px;
    margin-bottom: 20px;
}

.testibox p {
    font-size: 16px;
    font-weight: 400;
    color: #1d1d1d;
    line-height: 26px;
    padding-bottom: 0;
    height: 130px;
    overflow: hidden;
    overflow-y: auto;
}

.testibox p::-webkit-scrollbar {
    width: 5px;
}

.testibox h6 {
    font-size: 20px;
    font-weight: 700;
    color: #1d1d1d;
    line-height: normal;
    padding-top: 20px;
}

.testislider .slick-arrow {
    z-index: 1;
    position: absolute;
    top: -50px;
    font-size: 0;

}

.testislider .slick-arrow:after {
    content: 'Previous';
    position: absolute;
    font-size: 16px;
    color: #fff;
    line-height: normal;
    text-transform: uppercase;
    transition: all 0.4s ease-In-out;
}

.testislider .slick-prev.slick-arrow {
    right: 150px;
}

.testislider .slick-next.slick-arrow {
    right: 50px;
}

.testislider .slick-prev.slick-arrow:after {}

.testislider .slick-next.slick-arrow:after {
    content: 'Next';
}

.testislider .slick-arrow:hover:after {
    color: #fa426a;
    transition: all 0.4s ease-In-out;
}


/*My Code*/
/*Blog Page*/
.innerBanner h2 span {
    color: #ad082a;
}

.blogSection {
    width: 100%;
    height: auto;
    padding: 170px 0 190px;
    background-repeat: no-repeat;
    background-size: 80% 80%;
    background-position: left bottom;
    position: relative;
}

.blogSection:after {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 50px solid #9b5fc2;
    border-left: 40px solid transparent;
    position: absolute;
    top: 70px;
    left: 400px;
}

.blogSection:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 90px solid #d93458;
    border-left: 80px solid transparent;
    position: absolute;
    bottom: 330px;
    right: 180px;
}

.blohbox {
    width: 100%;
    height: auto;
    overflow: hidden;
    box-shadow: 0px 0px 43px 0px rgba(175, 175, 175, 0.32);
    margin-bottom: 50px;
    position: relative;
}

.blohbox:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 60px solid #ffffff;
    border-left: 70px solid transparent;
    position: absolute;
    top: 40px;
    right: 30px;
}

.blogoverlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
    transition: all 0.4s ease-in-out;
}

.blohbox:hover .blogoverlay {
    opacity: 1;
    transition: all 0.4s ease-in-out;
}

.blogcont {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    padding: 0 40px 40px;
}

.blogcont h6 {
    color: #fff;
    font-size: 22px;
    line-height: 1.15;
    font-weight: 800;
    padding-bottom: 25px;
}

.blogcont p {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
}

/*Blog Page*/

/*Blog Detail Page*/
.blog-detailSection {
    width: 100%;
    height: auto;
    padding: 150px 0 110px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
}

.blog-detailSection:after {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 45px solid #9b5fc2;
    border-left: 37px solid transparent;
    position: absolute;
    top: 160px;
    left: 100px;
}

.blog-detailSection:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 90px solid #d93458;
    border-left: 80px solid transparent;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 110px;
    margin: auto;
}

.blogdetailbox h6 {
    font-size: 50px;
    color: rgb(16, 16, 16);
    line-height: 1.486;
    font-weight: 500;
    letter-spacing: -2px;
}

.blogdetailbox h4 {
    color: rgb(16, 16, 16);
    line-height: 1.486;
    font-weight: 800;
    font-size: 50px;
    letter-spacing: -2px;
    padding-bottom: 25px;
}

.blogdetailbox span {
    color: rgb(16, 16, 16);
    font-style: 20px;
    font-weight: 500;
    letter-spacing: -0.8px;
    padding: 25px 0 40px;
    line-height: normal;
}

.blogdetailbox p {
    color: #adabab;
    font-size: 24px;
    font-weight: 500;
    line-height: 40px;
    padding-bottom: 50px;
}

.blogdetailbox h5 {
    text-transform: uppercase;
    color: #adabab;
    font-weight: 800;
    font-size: 24px;
    line-height: 1.2;
    padding-top: 70px;
    padding-bottom: 5px;
}

.blogdetailbox a {
    font-size: 22px;
    color: #d93458;
    font-weight: 600;
    line-height: 1;
    margin-top: 8px;
}

.blogdetailbox a:hover {
    color: #00b38c;
}

.blogdetail-img img {
    width: 100%;
    height: auto;
}

.bd-img img {
    width: 100%;
    height: 510px;
}

.bd-social {
    position: relative;
}

.bd-sociallist {
    position: absolute;
    top: 5px;
    right: -70px;
}

.bd-sociallist ul li {
    font-size: 12px;
    color: #9babac;
    text-align: center;
}

.bd-sociallist ul li span {
    font-size: 17px;
    color: #0b0b0c;
    display: block;
    padding: 0;
}

.bd-sociallist ul li:nth-child(2) a {
    color: #556da7;
}

.bd-sociallist ul li:nth-child(3) a {
    color: #25cada;
}

.bd-sociallist ul li:nth-child(4) a {
    color: #c40523;
}

.bd-sociallist ul li a:hover {
    color: #060606;
}

/*Blog Detail Page*/

.servinsec3 {
    padding-bottom: 50px;
}

.servinsec2 h5 {
    padding-bottom: 20px;
}

.servinsec2 h5:before {
    display: none;
}

.servinsec2 p {
    padding-bottom: 20px;
}

/*Service Detail Pages*/
.servsec6 {
    padding: 150px 200px;
    background-repeat: no-repeat;
    background-size: cover;
}

.servsec6con h4 {
    font-size: 50px;
    font-weight: 800;
    letter-spacing: -2px;
    color: #9b5fc2;
    line-height: 55px;
    padding-bottom: 30px;
}

.servsec6con p {
    font-size: 20px;
    font-weight: 300;
    color: #adabab;
    line-height: 30px;
    padding-bottom: 30px;
}

.servsidesix {
    position: relative;
    height: auto;
    width: 100%;
    margin-top: 150px;
    border-radius: 10px;
    padding: 50px;
    background-color: #fff;
    box-shadow: 0px 3px 27px 0px rgba(90, 90, 90, 0.44);
}

/* .servsidesix:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 70px solid #9b5fc2;
    border-left: 80px solid transparent;
    top: -50px;
    right: 60px;
} */

.servsidesix h5 {
    font-size: 35px;
    font-weight: 800;
    color: #9b5fc2;
    line-height: normal;
    padding-bottom: 30px;
}

.servsidesix p {
    font-size: 20px;
    font-weight: 300;
    color: #494949;
    line-height: 30px;
    padding-bottom: 30px;
}

.servsec7 {
    padding: 100px 200px;
    padding-top: 0;
}

.servsec7 h4 {
    font-size: 50px;
    font-weight: 800;
    letter-spacing: -2px;
    line-height: normal;
    padding-bottom: 30px;
    color: #9b5fc2;
}

.servsec7 p {
    font-size: 24px;
    font-weight: 300;
    color: #939393;
    line-height: 35px;
}

.servpb {
    padding-bottom: 100px;
}

.servvbox {
    padding: 50px;
    background-color: #000002;
    /*height:380px;overflow-y:auto;*/
    margin-bottom: 20px;
}

.servvbox h5 {
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    padding-bottom: 20px;
    line-height: normal;
}

.servvbox p {
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    line-height: 28px;
    height: 365px;
    overflow: hidden;
    overflow-y: auto;
}

.servvbox p::-webkit-scrollbar {
    width: 5px;
}

.servvbox2 {
    background-color: #939393;
}

.servvbox3 {
    background-color: #dbdbdb;
}

.servvbox3 p {
    color: #535353;
}

.servvbox3 h5 {
    color: #535353;
}

.srvlst {
    font-size: 0;
    display: block;
}

.srvlst li {
    font-size: 18px;
    color: #adabab;
    font-weight: 300;
    line-height: 28px;
    padding-bottom: 5px;
}

.srvlst li.last {
    padding-bottom: 0px;
}

.srvlst h6 {
    font-size: 25px;
    font-weight: 700;
    color: #fff;
    line-height: normal;
}

.servsidesix h6 {
    font-size: 24px;
    font-weight: 800;
    color: #9b5fc2;
    line-height: normal;
    padding-bottom: 20px;
}

.uxexservsec .servsidesix {
    margin-top: 0;
}

.servsec1 h6 {
    font-size: 30px;
    font-weight: 800;
    color: #000;
    line-height: normal;
    padding-bottom: 15px;
    padding-left: 50px;
}

.servsec6con h5 {
    font-size: 40px;
    font-weight: 800;
    letter-spacing: -2px;
    color: #191919;
    line-height: 40px;
    padding-bottom: 30px;
}

.servsidesix .srvlst {
    padding-bottom: 20px;
}

.servsidesix .srvlst p {
    padding-bottom: 0;
}

.servsidesix3 {}

.servsidesix3 h6 {
    color: #000;
}

.servsidesix3 .srvlst li {
    color: #000;
}

.servsidesix .srvlst li {
    color: #535353;
    padding-bottom: 15px;
}

.servsidesix .srvlst li strong {
    font-weight: 600;
}

.servsec1pads {
    padding: 50px 0;
}

/* Main Service */
.mnservsec {
    padding: 150px 200px 100px;
    position: relative;
}


.mnservsec h4 {
    font-size: 58px;
    font-weight: 800;
    color: #9b5fc2;
    line-height: 65px;
    padding-bottom: 20px;
}

.mnservsec p {
    font-size: 20px;
    font-weight: 400;
    color: #adabab;
    line-height: 30px;
}

.mnservsec1 {
    padding: 100px 200px 0;
}

.mnservsec1 .container-fluid {
    border-bottom: 1px solid #b3b3b3;
    padding-bottom: 100px;
}

.mnservsec1 h4 {
    font-size: 45px;
    font-weight: 800;
    color: #9b5fc2;
    line-height: 50px;
    padding-bottom: 30px;
    padding-top: 10px;
}

.mnservsec1 p {
    font-size: 20px;
    font-weight: 300;
    color: #adabab;
    line-height: 30px;
    padding-bottom: 30px;
}

.mnservsec1 a {
    width: 300px;
    height: 80px;
    background-color: #9b5fc2;
    color: #fff;
    line-height: 80px;
    font-weight: 800;
    font-size: 25px;
    text-align: center;
    transition: all 0.4s ease-In-out;
    border-radius: 10px;
}

.mnservsec1 a:hover {
    background-color: #fff;
    color: #000;
    transition: all 0.4s ease-In-out;
}

.mnservsec2 {
    position: relative;
}

.mnservsec2 .container-fluid {
    padding-bottom: 250px;
}

.mnservsec2:before {
    content: '';
    position: absolute;
    height: 754px;
    width: 922px;
    /* background-image: url(../images/service-icons/beforeone.png); */
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    bottom: 130px;
    margin: auto;
    z-index: -1;
}

.mnservsec2:after {
    content: '';
    position: absolute;
    height: 265px;
    width: 264px;
    /* background-image: url(../images/service-icons/dots.png); */
    background-repeat: no-repeat;
    background-size: cover;
    right: 80px;
    bottom: 50px;
    margin: auto;
    z-index: -1;
}

.mnservsec3 {
    position: relative;
}

.mnservsec3 .container-fluid {
    padding-bottom: 250px;
}

.mnservsec3:before {
    content: '';
    position: absolute;
    height: 1047px;
    width: 855px;
    /* background-image: url(../images/service-icons/beforetwo.png); */
    background-repeat: no-repeat;
    background-size: cover;
    right: 0;
    top: -200px;
    margin: auto;
    z-index: -1;
}

.mnservsec4 .container-fluid {
    border-bottom: none;
}

/* End Styling */

/* Hamburger Menu */
.menu-Bar {
    z-index: 22;
    background: transparent;
    border-radius: 50%;
    position: relative;

    height: 100%;
    width: 100%;
    transition: all 0.4s ease-In-out;
}

.menu-Bar span {
    display: block;
    height: 4px;
    background: #fff;
    position: absolute;
    transition: .6s all;
}

.menu-Bar span:nth-child(1) {
    top: 0;
    width: 60px;
}

.menu-Bar span:nth-child(2) {
    top: 10px;
    transform-origin: left;
    width: 50px;
}

.menu-Bar span:nth-child(3) {
    top: 20px;
    width: 40px;
}

.menu-Bar.open {
    background-color: #e43a5f;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    transition: all 0.4s ease-In-out;
}

.menu-Bar.open span {
    background: #c56bff;
}

.menu-Bar.open span:nth-child(1) {
    transform: rotate(45deg);
    top: 27px;
    transform-origin: right-center;
    width: 70%;
}

.menu-Bar.open span:nth-child(2) {
    width: 0;
    opacity: 0;
}

.menu-Bar.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 27px;
    transform-origin: right-center;
    width: 70%;
}

.menuWrap {
    position: fixed;
    left: -210%;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    /* background-image: url(../images/bg-menu.jpg); */
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    transition: all 0.4s ease;
    z-index: 3;
    width: 100vw;
}

.menuWrap.open {
    left: 0px;
    opacity: 1;
}

.menu-Bar p {
    font-size: 16px;
    font-weight: 400;
    color: #b7b7b7;
    position: absolute;
    bottom: -30px;
    left: -15px;
    z-index: 9;
    width: 90px;
    text-align: center;
    opacity: 0;
}

.menu-Bar.open p {
    opacity: 1;
}

.mainMenu {}

.mainMenu li {
    margin-bottom: 15px;
}

.mainMenu li.last {}

.mainMenu li a {
    position: relative;
    display: block;
    font-size: 40px;
    line-height: normal;
    font-weight: 700;
    color: #fff;
    padding-left: 30px;
}

.mainMenu li a:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 45px solid #e43a5f;
    border-left: 55px solid transparent;
    left: -50px;
    opacity: 0;
    transition: all 0.4s ease-In-out;
}

.mainMenu li a:hover:before,
.mainMenu li.active a:before {
    opacity: 1;
    transition: all 0.4s ease-In-out;
}

.menusubs {
    max-width: 450px;
}

.menusubs h6 {
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    line-height: normal;
    padding-bottom: 15px;
}

.menusubs form {
    position: relative;
    padding-bottom: 50px;
    margin-bottom: 20px;
    border-bottom: 1px solid #878787;
}

.menusubs form input {
    width: 450px;
    height: 65px;
    background-color: #0dbca7;
    padding: 0 20px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
}

.menusubs form input::placeholder {
    color: #fff;
}

.menusubs form button {
    height: 65px;
    width: 65px;
    background-color: #007466;
    text-align: center;
    line-height: 85px;
    position: absolute;
    right: 0;
    top: 0;
    display: block;
}

.menusubs form button img {
    max-width: 40%;
}

.menusocial {
    font-size: 0;
    display: block;
}

.menusocial li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
}

.menusocial li.last {
    margin-right: 0;
}

.menusocial li a {
    display: block;
    font-size: 30px;
    color: #fff;
}

.menusocial li a:hover {
    color: #e43a5f;
}

.menuslide {
    margin-bottom: 50px;
}

.menubottom {}

.menubottom strong {
    font-size: 20px;
    font-weight: 500;
    color: #b7b7b7;
    line-height: normal;
    text-align: left;
}

.menubottom a {
    display: block;
    text-align: right;
}

.respdropdown,
.respsubmenu,
.respdropnav span {
    display: none;
}

.hmsec5 .field span {
    width: 100%;
    border: 0;
    margin-bottom: 0;
    height: auto;
    display: inline-block;
    position: relative !important;
}

.page-id-133 .field input,
.field select,
.field textarea {
    box-shadow: 0px 0px 35px 0px rgb(68 68 68 / 15%);
}

html {
    overflow-x: hidden;
}

ul.clientlist li {
    display: inline-block;
    margin-right: 0;
    padding: 0;
    padding-right: 0;
    vertical-align: top;
    width: 45%;
}

ul.clientlist li a img {
    transition: all 0.4s ease-In-out;
    max-width: 100%;
    filter: grayscale(1);
}

ul.clientlist li a:hover img {
    filter: grayscale(0);
    transition: all 0.4s ease-In-out;
}

/*Over Lay*/
.ovr-hiddn {
    overflow: hidden;
}

.overlay:after {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.85);
    z-index: 1;
}

.overlay {
    display: none;
}

.overlay.active {
    display: block;
}

/* POP UP */
.mpop {
    display: flex !important;
}

.popupMain {
    position: fixed;
    overflow: hidden;
    overflow-y: auto;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: 700px;
    height: 70vh;
    background-color: #ffffff;
    margin: auto;
    z-index: 10;
    align-items: center;
    display: none;
}

.closePop {
    font-size: 34px;
    color: #9b5fc2;
    font-weight: 700;
    position: absolute;
    right: 15px;
    top: 15px;
}

/*.closePop:hover{color:#cefa05;}*/
.popinner {
    padding: 20px 50px 0 50px;
}

.pop-hd-wrp h3 {
    color: #9b5fc2;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -2px;
    padding-bottom: 20px;
}

.pop-hd-wrp h3 span {
    color: #fff;
}

.popfield h4 {
    font-size: 27px;
    font-weight: 700;
    color: #101010;
    line-height: 50px;
    padding-bottom: 10px;
    letter-spacing: -2px;
}

.pop-hd-wrp,
.popinner form {
    /* padding-left:40px; */
}

.popfield {
    margin-bottom: 20px;
}

.popfield input[type="text"],
.popfield input[type="number"],
.popfield input[type="email"] {
    width: 100%;
    height: 60px;
    box-shadow: 0px 0px 2px 1px rgb(0 0 0 / 50%);
    background-color: #fff;
    padding: 0 30px;
    font-size: 16px;
    font-weight: 500;
    color: #8c8c8c;
    transition: all 0.4s ease-In-out;
}

.popfield input:hover,
.popfield input:focus {
    box-shadow: 0px 0px 10px 0px rgb(68 68 68 / 15%);
    border-color: #ffffff;
    transition: all 0.4s ease-In-out;
}

.popfield button {
    margin-top: 20px;
    background-color: #9b5fc2;
    width: 80%;
    height: 70px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    line-height: 1;
    margin: auto;
    display: block;
    transition: all 0.4s ease-In-out;
}

.popfield button:hover {
    background-color: #9b5fc2;
    border: 1px solid transparent;
    transition: all 0.4s ease-In-out;
}

.popfield ul li {
    display: inline-block;
    width: 100%;
    /* margin-right:15px; */
    /* padding-bottom: 25px; */
}

.popfield input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-right: 15px;
}

.popfield label {
    font-size: 16px;
    font-weight: 500;
    color: #adabab;
    line-height: 1;
    padding-bottom: 0;
}

.popfield .wpcf7-list-item {
    display: inline-block;
    margin: 0 0 1em 0;
    width: 45%;
    vertical-align: middle;
}

.blogdetail-img.bd-social {
    height: 650px;
    overflow: hidden;
    padding-right: 100px;
}

.bd-sociallist {
    right: 0;
}

/* career popup */
.popcarer {
    position: fixed;
    overflow-y: auto;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: 700px;
    height: 70vh;
    background-color: #ffffff;
    margin: auto;
    z-index: 10;
    align-items: center;
    display: none;
}

.popcarer {
    padding: 50px;
}

.popcarer p {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    line-height: 26px;
    padding-bottom: 30px;
}

.popcarer .popinner {
    padding: 0;
}

.popcarer1 {
    position: fixed;
    overflow-y: auto;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: 700px;
    height: 70vh;
    background-color: #ffffff;
    margin: auto;
    z-index: 10;
    align-items: center;
    display: none;
}

.popcarer1 {
    padding: 50px;
}

.popcarer1 p {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    line-height: 26px;
    padding-bottom: 30px;
}

.popcarer1 .popinner {
    padding: 0;
}

.popcarer2 {
    position: fixed;
    overflow-y: auto;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: 700px;
    height: 70vh;
    background-color: #ffffff;
    margin: auto;
    z-index: 10;
    align-items: center;
    display: none;
}

.popcarer2 {
    padding: 50px;
}

.popcarer2 p {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    line-height: 26px;
    padding-bottom: 30px;
}

.popcarer2 .popinner {
    padding: 0;
}

.popcarer3 {
    position: fixed;
    overflow-y: auto;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: 700px;
    height: 70vh;
    background-color: #ffffff;
    margin: auto;
    z-index: 10;
    align-items: center;
    display: none;
}

.popcarer3 {
    padding: 50px;
}

.popcarer3 p {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    line-height: 26px;
    padding-bottom: 30px;
}

.popcarer3 .popinner {
    padding: 0;
}

/* Service Popup */
.popserv {
    position: fixed;
    overflow-y: auto;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: fit-content;
    height: fit-content;
    text-align: center;
    background-color: #ffffff;
    margin: auto;
    z-index: 10;
    align-items: center;
    display: none;
}

.popserv {
    padding: 50px;
}

.popserv p {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    line-height: 26px;
    padding-bottom: 30px;
}

.popserv .popinner {
    padding: 0;
}

.popserv span {
    width: 0;
    height: 0;
    border-bottom: 45px solid #fa426a;
    border-left: 40px solid transparent;
    margin-bottom: 20px;
}

.popserv h4 {
    font-size: 46px;
    font-weight: 800;
    color: #fa426a;
    line-height: normal;
    padding-bottom: 50px;
}

.popserv h4 strong {
    color: #000;
}

.popserv ul {
    font-size: 0;
    display: block;
}

.popserv ul li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
}

.popserv ul li.last {
    margin-right: 0;
}

.popserv ul li a {
    display: block;
    width: 300px;
    height: 75px;
    background-color: #000;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    line-height: 75px;
    color: #fff;
}

.popserv ul li a:hover {
    background-color: #9b5fc2;
}



.our-client-new .slick-slide img {

    width: 100%;
}

.our-clients-sec {
    padding: 100px 0;
    background-color: #1e1e1e;
}

.our-clients-sec .slick-arrow {
    height: 60px;
    width: 60px;
    border: 1px solid #c8c8c8;
    border-radius: 100%;
    background-color: transparent;
    position: absolute;
    left: -110px;
    top: 45%;
    transition: .5s;

}

.our-clients-sec .slick-arrow:hover {
    background-color: #fff;
    box-shadow: 0 0 10px 0 #00000029;
    border-color: transparent;
}

.our-clients-sec .slick-arrow.next-arrow {
    left: auto;
    right: -110px;
}

/* Animations */
.vert-move {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
}

.vert-move {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-20px);
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-20px);
    }
}

a.mobpop {
    display: none !important;
}

.page-id-111 ul.clientlist li {
    width: 33.3%;
}

.page-id-111 .clientsec {
    padding-bottom: 0;
}

.productsec {
    padding: 100px 0 70px;
}

.prodbox {
    text-align: center;
    padding-bottom: 30px;
    ;
}

.prodimg {
    position: relative;
    height: 270px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    transition: all 0.2s ease-In-out;
}

.prodimg a {
    background-color: #99cc32;
    padding: 15px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    height: auto;
    width: 100%;
    position: absolute;
    bottom: -45px;
    left: 0;
    right: 0;
    margin: auto;
    transition: all 0.2s ease-In-out;
}

.prodimg:hover a {
    bottom: 0;
    transition: all 0.2s ease-In-out;
}

.prodbox h6 {
    font-size: 18px;
    font-weight: 400;
    color: #101010;
    line-height: normal;
    padding: 10px 0;
}

.prodbox span {
    display: block;
    font-size: 17px;
    font-weight: 400;
    color: #747474;
    line-height: normal;
}


/* Responsive Media Queries */
@media (max-width:1660px) {
    .main-header {
        padding: 10px;
    }

    .mainBanner {
        padding: 0 50px;
    }

    .hmsec1 {
        padding-left: 50px;
    }

    .hmsec2 {
        padding: 150px 50px 50px;
    }

    .hmsec3 {
        padding: 150px 50px 80px;
    }

    .projectswrap {
        padding: 0;
        padding-right: 70px;
    }

    .sliderwrap {
        width: 100%;
        height: 100%;
    }

    .hmsec4 {
        padding: 150px 50px 100px;
        padding-right: 0;
    }

    .clientdesc {
        padding: 0 50px;
    }

    .hmsec5 {
        padding: 150px 50px;
    }

    .blackcon,
    .ftr-grey2 {
        padding: 80px 50px;
    }

    .ftr-grey {
        padding: 80px 30px;
    }

    .ftr-reviews {
        padding: 80px 30px;
    }

    .abtsec3 {
        padding: 100px 50px;
    }

    .team {
        padding-left: 50px;
    }

    .servsec2 {
        padding-left: 0;
        padding-bottom: 0;
    }

    .servsec3 {
        padding: 250px 50px 0;
    }

    .servinsec {
        padding: 0 50px 100px;
    }

    .showcase {
        padding: 0 50px;
    }

    .mnservsec {
        padding: 150px 50px 100px;
    }

    .mnservsec:before {
        left: -20px;
    }

    .mnservsec1 {
        padding: 100px 50px 0;
    }

    .servsec6 {
        padding: 150px 50px;
    }

    .servsec7 {
        padding: 100px 50px;
        padding-top: 0;
    }

    .popupMain {
        height: 100%;
    }
}

@media (max-width:1600px) {

    .our-clients-sec {
        padding: 100px;
    }

    .our-clients-sec .slick-arrow {
        left: -90px;
    }

    .our-clients-sec .slick-arrow.next-arrow {
        right: -90px;
    }
}

@media (max-width:1440px) {
    .menu li {
        margin-right: 15px;
    }

    .clientwrap h3 {
        font-size: 50px;
    }

    .clientwrap ul li span {
        font-size: 70px;
        line-height: 1;
        letter-spacing: -3px;
    }

    .clientwrap ul li h6 {
        font-size: 24px;
    }

    .clientwrap ul li {
        margin-right: 35px;
    }

    .clientwrap {
        height: auto;
    }

    ul.clientlist li {
        width: 47%;
    }

    .clientdesc p {
        font-size: 20px;
        line-height: 30px;
    }

    .ftr-social li {
        margin-right: 10px;
    }

    .tablist li {
        margin-right: 15px;
    }

    .tablist li a {
        width: 250px;
        height: 60px;
        font-size: 18px;
        line-height: 60px;
    }

    .bd-sociallist {
        right: 20px;
        background-color: #fff;
        padding: 10px;
        border-radius: 7px;
    }

    .blog-detailSection:after {
        top: 70px;
        left: 125px;
    }

    .blog-detailSection:before {
        right: 10px;
    }

    .clientwrap ul {
        display: flex;
        justify-content: space-between;
    }

    .blogdetail-img.bd-social {
        padding-right: 0;
    }
}

@media (max-width:1200px) {
    br {
        display: none;
    }

    .clientwrap ul li span:before {
        font-size: 70px;
        right: -50px;
        top: 0;
    }

    .hmsec1 {
        padding-left: 30px;
    }

    .hmsec1 h4 {
        font-size: 55px;
        letter-spacing: -3px;
        padding-bottom: 30px;
    }

    .hmsec2 {
        padding: 150px 20px 50px;
    }

    .projectswrap {
        padding-right: 30px;
    }

    .projectswrap p {
        padding-bottom: 30px;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    ul.menu li a {
        /* display: none; */
    }

    .menuWrap {
        overflow-y: auto;
        padding: 70px 20px 20px;
        display: block;
    }

    .container {
        position: relative;
    }

    header .header-top {
        /* display: none; */
    }

    li.respdropnav.opens .respdropdown,
    li.responssub.open1 .respsubmenu,
    .respdropnav span {
        display: block;
    }

    .respdropnav {
        position: relative;
    }

    .respdropnav a {
        position: relative;
    }

    .responssub {
        position: relative;
    }

    .respdropnav span {
        position: absolute;
        font-size: 14px;
        color: #fff;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .respdropdown,
    .respsubmenu {
        padding-top: 20px;
    }

    ul.respdropdown {
        padding-left: 15px;
    }

    ul.respsubmenu {
        padding-left: 15px;
    }

    .mainMenu li a {
        font-size: 18px;
    }

    ul.respdropdown li a {
        font-size: 16px;
    }

    ul.respdropdown li span {
        right: -25px
    }

    .respdropnav span {
        right: -25px
    }

    .respdropnav a,
    ul.respdropdown li a {
        display: inline-block;
        position: relative;
        width: 95%;
    }

    .mainMenu li a:before {
        display: none;
    }

    .mainMenu li a {
        padding: 0;
    }

    .tablist li a {
        width: auto;
        height: auto;
        font-size: 16px;
        line-height: 1;
        padding: 20px;
    }

    .servsec6con h4 {
        font-size: 32px;
        letter-spacing: -1px;
        line-height: 1.2;
        padding-bottom: 20px;
    }

    .servsidesix h5 {
        font-size: 25px;
        padding-bottom: 20px;
    }

    .main-header {
        overflow: hidden;
    }

    a.mobpop {
        display: block !important;
    }


}

@media (max-width:1024px) {
    .mainMenu li a:before {
        border-top: 35px solid #e43a5f;
        border-left: 48px solid transparent;
        left: -30px;
    }

    .menubottom strong {
        font-size: 16px;
    }

    .hmsec1 h4 {
        font-size: 50px;
        letter-spacing: -2.5px;
        padding-bottom: 30px;
        line-height: 60px;
    }

    .doslider .slick-slide,
    .doslider2 .slick-slide {
        margin: 0;
    }

    .dowrap a {
        font-size: 20px;
    }

    .projectswrap p {
        line-height: 24px;
    }

    ul.clientlist li {
        width: 45%;
    }

    .clientwrap h3 {
        font-size: 45px;
        padding-bottom: 40px;
    }

    .clientwrap ul li span {
        font-size: 60px;
        line-height: 50px;
    }

    .clientwrap ul li span:before {
        font-size: 60px;
        right: -35px;
        top: -15px;
    }

    .clientwrap ul li h6 {
        font-size: 20px;
    }

    .widget h6 {
        font-size: 18px;
    }

    .ftr-social li a {
        font-size: 16px;
    }

    .quiklnks li a {
        font-size: 14px;
    }

    .careersec3 .clientwrap ul li span {
        font-size: 40px;
        position: relative;
        /* display:block; */
    }

    .careersec3 .clientwrap ul li span:before {
        font-size: 40px;
        line-height: 52px;
        right: -20px;
        top: -3px;
        position: absolute;
    }

    .blogdetailbox h6,
    .careersec3 h4 {
        font-size: 30px;
    }

    .blogdetailbox h4 {
        font-size: 35px;
    }

    .blogdetailbox p {
        font-size: 20px;
        padding-right: 40px;
        line-height: 30px;
        padding-bottom: 30px;
    }

    .clientwrap ul li span {
        letter-spacing: -2px;
    }

    .servsidesix {
        margin-top: 100px;
    }

    .servvbox {
        padding: 10px;
        margin-bottom: 10px;
    }

    .servsec7 h4,
    .servinsec h5,
    .servsec4 h4,
    .servsec5 h4,
    .servsec1 h4,
    .servsec2 h4,
    .servsec3 h4 {
        font-size: 35px;
        letter-spacing: -0.5px;
        padding-bottom: 20px;
        line-height: 1.4;
    }

    .servsec7 p,
    .servinsec p,
    .servsec1 p {
        font-size: 18px;
        line-height: 1.7;
    }

    .servvbox h5,
    .servsbx h6,
    .servsbx h5 {
        font-size: 22px;
        padding-bottom: 10px;
    }

    .servvbox p {
        font-size: 14px;
        line-height: 1.5;
    }

    .servsec5 ul li a {
        font-size: 20px;
    }

    .servsbx h6 {
        letter-spacing: 0;
    }

    .servbxcon,
    .servsbx {
        padding: 10px;
    }

    .servbxcon p {
        font-size: 14px;
        line-height: 1.8;
    }
}

@media (max-width:992px) {
    .hmsec1 h4 {
        letter-spacing: -3px;
    }

    .dowrap a {
        font-size: 18px;
    }

    .showcase {
        padding: 0 20px;
    }

    .tablist li {
        margin-right: 5px;
    }

    .blogcont {
        padding: 0 10px 10px;
    }

    .blogcont h6 {
        font-size: 18px;
        padding-bottom: 10px;
    }

    .blogcont p {
        font-size: 14px;
    }

    .blohbox:after {
        border-top: 40px solid #ffffff;
        border-left: 50px solid transparent;
        top: 10px;
        right: 10px;
    }

    .blogdetailbox h4 {
        font-size: 30px;
    }

    .careersec3 .clientwrap ul li span:before {
        right: -15px;
    }

    .careersec3 .clientwrap ul li h6 {
        font-size: 18px;
    }

    .clientwrap ul li span {
        letter-spacing: -2px;
        font-size: 35px;
    }

    .clientwrap ul li span:before {
        font-size: 35px;
        right: -20px;
        top: -15px;
    }

    .clientwrap ul li h6 {
        font-size: 16px;
    }

    .servsec6con h4 {
        font-size: 22px;
        letter-spacing: -0.5px;
        line-height: 1.2;
        padding-bottom: 10px;
    }

    .servsec6con p {
        font-size: 16px;
        font-weight: 300;
        color: #adabab;
        line-height: 1.5;
        padding-bottom: 20px;
    }

    .servsidesix h5 {
        font-size: 19px;
        padding-bottom: 20px;
    }

    .servsec6con h5,
    .srvlst h6 {
        font-size: 20px;
        letter-spacing: -1px;
        padding-bottom: 15px;
    }

    .srvlst h6 {
        padding-bottom: 5px;
        letter-spacing: 0;
    }

    .servsidesix p {
        font-size: 14px;
        line-height: 1.5;
        padding-bottom: 20px;
    }

    .mnservsec h4 {
        font-size: 35px;
        line-height: 1.2;
    }

    .mnservsec p {
        font-size: 16px;
        line-height: 1.6;
    }

    .mnservsec {
        padding: 80px 50px 50px;
    }

    .mnservsec1 {
        padding: 50px 50px 0;
    }

    .mnservsec1 h4 {
        font-size: 30px;
        line-height: 1.4;
        padding-bottom: 20px;
        padding-top: 10px;
    }

    .mnservsec1 p {
        font-size: 16px;
        line-height: 1.5;
        padding-bottom: 20px;
    }

    .mnservsec1 a {
        width: 200px;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
    }

    .mnservsec1 .container-fluid {
        border-bottom: 1px solid #b3b3b3;
        padding-bottom: 50px;
    }
}

@media (max-width:824px) {
    .menuWrap {
        display: block;
    }

    .logo img {
        max-width: 50%;
    }

    .mainBanner h1 {
        font-size: 45px;
        line-height: 50px;
        letter-spacing: -1.5px;
    }

    .mainMenu li a {
        font-size: 18px;
    }

    .mainMenu li a:before {
        border-top: 25px solid #e43a5f;
        border-left: 38px solid transparent;
        left: -15px;
    }

    .menusubs form input {
        width: 100%;
        padding: 0 80px 0 20px;
    }

    .hmsec1 h4 {
        font-size: 36px;
        letter-spacing: -1.5px;
        line-height: 46px;
    }

    .hmsec1 p {
        font-size: 14px;
        line-height: 24px;
        padding-bottom: 20px;
    }

    .hmsec1 a {
        width: 200px;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
    }

    .hmsec1 h6 {
        font-size: 18px;
    }

    .hmsec2 h6 {
        font-size: 18px;
    }

    .hmsec2 h4 {
        font-size: 36px;
        line-height: 46px;
        letter-spacing: -1px;
    }

    .dowrap a {
        font-size: 14px;
    }

    .hmsec2 p {
        font-size: 14px;
        line-height: 24px;
    }

    .hmsec3 h2 {
        font-size: 18px;
    }

    .hmsec3 h4 {
        font-size: 36px;
        padding-bottom: 30px;
    }

    .projectswrap h5 {
        font-size: 22px;
        padding: 10px 0 10px;
    }

    .procounts {
        font-size: 90px;
        letter-spacing: -3px;
        height: 120px;
    }

    .projectswrap {
        padding-right: 10px;
    }

    .hmsec4 {
        padding: 150px 0 100px 30px;
    }

    .clientwrap {
        padding: 30px 30px 30px 30px;
        margin: 10px 20px 20px;
        height: 190px;
        width: auto;
    }

    .clientwrap h3 {
        font-size: 22px;
        padding-bottom: 10px;
    }

    .clientwrap ul li span {
        font-size: 20px;
        letter-spacing: -0px;
    }

    .clientwrap ul li span:before {
        font-size: 22px;
        right: -10px;
    }

    .clientwrap ul li h6 {
        font-size: 12px;
        font-weight: 600;
        padding-top: 0;
    }

    .clientdesc {
        padding: 0 10px;
    }

    .clientdesc p {
        font-size: 14px;
        line-height: 30px;
    }

    .clientdesc ul li a {
        width: 200px;
        height: 50px;
        font-size: 14px;
        line-height: 50px;
    }

    .hmsec5 h6 {
        font-size: 18px;
        padding-bottom: 10px;
    }

    .hmsec5 h4 {
        font-size: 36px;
        letter-spacing: -1px;
    }

    .field input,
    .field select,
    .field textarea {
        height: 50px;
        padding: 0 10px;
        font-size: 14px;
    }

    .field textarea {
        height: 170px;
        padding: 15px 10px;
    }

    .field button {
        width: 180px;
        height: 55px;
        font-size: 14px;
    }

    .blackcon,
    .ftr-grey2 {
        padding: 80px 20px;
    }

    .blackcon strong {
        font-size: 14px;
        padding-top: 10px;
    }

    .ftr-grey {
        padding: 80px 10px;
    }

    .ftr-reviews p {
        font-size: 14px;
        line-height: 1.4;
        padding-bottom: 20px;
        height: auto;
    }

    .menuWrap {
        overflow-y: auto;
        padding: 70px 0px;
    }

    .mainMenu li a {
        font-size: 16px;
    }

    ul.respdropdown li a {
        font-size: 16px;
    }

    /* .innerBanner{padding:0;765} */
    .abtsec3 {
        padding: 100px 20px;
    }

    .abtsec2 h4 {
        font-size: 55px;
    }

    .abtsec3 h4,
    .team h4 {
        font-size: 36px;
    }

    .abtsec1 h4 {
        font-size: 70px;
        line-height: 70px;
        letter-spacing: -2px;
    }

    .abtsec3 p {
        font-size: 16px;
        line-height: 26px;
        padding-bottom: 20px;
        padding-right: 50px;
    }

    .abtsec2 p,
    .team p {
        font-size: 16px;
        line-height: 26px;
    }

    .team {
        padding: 100px 20px 200px;
    }

    .contactdescp h4,
    .careersec1 h4,
    .careersec2 h4 {
        font-size: 32px;
        line-height: 46px;
        letter-spacing: -1px;
    }

    .contactdescp p,
    .careersec1 p {
        font-size: 16px;
        font-weight: 500;
        color: #8c8c8c;
        line-height: 24px;
    }

    .careersec1:after {
        display: none;
    }

    .carbox {
        height: 300px;
        padding: 30px;
        text-align: center;
        justify-content: center;
    }

    .carbox img {
        left: -20px;
        right: 0;
        margin: auto;
    }

    .carbox h6 {
        font-size: 18px;
        text-align: center;
    }

    .careersec3 .clientwrap ul li span:before {
        right: -15px;
    }

    .testimonial {
        background-position: top;
    }

    .innerBanner h2 {
        font-size: 50px;
    }

    .servsec1 h4 {
        padding: 0;
    }

    .servsec1 p {
        padding: 0;
        line-height: 1.5;
        font-size: 16px;
    }

    .servsec2 p {
        font-size: 16px;
        line-height: 1.5;
        padding-bottom: 15px;
    }

    .servsec2 a {
        height: 50px;
        font-size: 14px;
        line-height: 50px;
    }

    .servsbx {
        height: 240px
    }

    .srvboxlist li {
        margin-right: 20px;
        margin-bottom: 20px;
        width: 46%;
    }

    .servsbx h6 {
        font-size: 18px;
    }

    .pop-hd-wrp,
    .popinner form {
        padding-left: 0;
    }
}

@media (max-width:767px) {
    .logo {
        position: relative;
        top: 30px;
    }

    .menuWrap {
        overflow-y: auto;
        padding: 50px 20px;
    }

    /* .menu>li.last{width:0px;height:30px;} */
    .menu>li.last {
        width: 0;
        height: auto;
        margin-right: 30px;
    }

    .menu-Bar.open {
        height: 30px;
        width: 30px;
    }

    .menu-Bar.open span:nth-child(3) {
        top: 13px;
    }

    .menu-Bar.open span:nth-child(1) {
        top: 13px;
    }

    .menu-Bar p {
        font-size: 10px;
        text-align: left;
        bottom: -25px;
    }

    .mainMenu li {
        margin-bottom: 20px;
    }

    .mainMenu li a {
        font-size: 14px;
    }

    .menusubs h6 {
        font-size: 16px;
        padding-bottom: 15px;
        padding-top: 20px;
    }

    .menuslide .offset-1 {
        margin: 0;
    }

    .menusubs form {
        padding-bottom: 20px;
        margin-bottom: 10px;
    }

    .menusubs form input {
        font-size: 12px;
    }

    .menusocial li a {
        font-size: 20px;
    }

    .menubottom strong {
        font-size: 12px;
        padding-bottom: 20px;
    }

    .menubottom a {
        text-align: center;
        padding-top: 20px;
    }

    .mainBanner img {
        display: none;
    }

    .mainBanner {
        padding: 0 20px;
        height: auto;
        min-height: 500px;
    }

    .mainBanner h6,
    .innerBanner h6 {
        font-size: 14px;
    }

    .mainBanner h1,
    .innerBanner h2 {
        font-size: 22px;
        line-height: 32px;
        letter-spacing: -0.5px;
        min-height: 130px;
    }

    .homescroll {
        bottom: 0;
        font-size: 14px;
    }

    .hmsec1 {
        padding: 20px 0 0;
        z-index: 0;
    }

    .hmsec1 span {
        position: absolute;
        right: 10px;
    }

    .hmsec1 h4 {
        padding-right: 40px;
    }

    .hmsec1 h6,
    .hmsec2 h6,
    .hmsec3 h2,
    .hmsec5 h6,
    .contactdescp h6 {
        font-size: 16px;
        padding-bottom: 0;
    }

    .hmsec1 h4,
    .hmsec2 h4,
    .hmsec3 h4,
    .hmsec5 h4,
    .abtsec1 h4,
    .abtsec2 h4 {
        font-size: 22px;
        letter-spacing: -0.5px;
        line-height: 28px;
        padding-bottom: 10px;
    }

    .hmsec1 p {
        font-size: 14px;
        line-height: 24px;
        padding-bottom: 10px;
    }

    .hmsec1img {
        padding-top: 20px;
    }

    .hmsec2 {
        padding: 30px 0px 30px;
    }

    .hmsecpad {
        padding-bottom: 30px;
    }

    .dowrap {
        padding: 40px 0px 0;
    }

    .hmsec3 {
        padding: 30px 20px 15px;
        background-position: left -66px;
    }

    .hmsec3 span {
        margin: 0;
    }

    .hmsec2:after {
        left: auto;
        right: 0;
        bottom: auto;
    }

    .procounts {
        font-size: 50px;
        letter-spacing: -3px;
        height: 40px;
        line-height: 50px;
    }

    .projectswrap p {
        padding-bottom: 20px;
    }

    .projectswrap {
        padding-bottom: 20px;
    }

    .slider-nav .slick-dots {
        padding: 30px 0 0;
    }

    .hmsec4 {
        padding: 15px 20px 10px;
    }

    .hmsec4 span.hmsec4shape {
        display: none;
    }

    .hmsec4 h4 {
        font-size: 22px;
        padding-bottom: 20px;
    }

    ul.clientlist li {
        width: 22%;
        padding: 10px;
    }

    .clientwrap:after,
    .hmsec4:before {
        display: none;
    }

    .clientwrap {
        margin: 10px 0 20px;
        height: auto;
        padding: 20px 10px;
    }

    .clientwrap:before {
        height: 100%;
        background-position: left;
    }

    .clientwrap h3 {
        text-align: center;
    }

    .clientdesc p {
        font-size: 14px;
        padding-bottom: 20px;
        line-height: 24px;
    }

    .clientwrap ul li span:before {
        right: -8px;
    }

    .clientdesc ul li {
        margin-right: 0;
        display: block;
        margin-bottom: 10px;
    }

    .clientdesc ul li a {
        width: 100%;
        display: block;
        margin: auto;
        letter-spacing: 0.5px;
        height: 40px;
        line-height: 40px;
    }

    .hmsec5 {
        padding: 30px 10px 10px;
    }

    .hmsec5 span {
        border-left: 45px solid transparent;
        margin-bottom: 0px;
        position: absolute;
        right: 0;
    }

    .hmsec5 h4 {
        padding-right: 30px
    }

    .hmsec5wrp {
        padding: 0;
    }

    .mapimg {
        display: none;
    }

    .blackcon,
    .ftr-grey2,
    .ftr-grey,
    .ftr-reviews {
        padding: 20px 20px;
        height: auto;
    }

    .blackcon p,
    .blackcon a {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .ftrlogo img {
        width: 35%;
        margin-bottom: 10px;
    }

    .widget h6 {
        font-size: 16px;
        padding-bottom: 20px;
    }

    .quiklnks li {
        margin-bottom: 15px;
    }

    .ftr-social {
        padding-bottom: 20px;
    }

    .ftr-grey2 button {
        width: 100%;
    }

    .homescroll span {
        height: 46px;
        margin-bottom: 5px;
    }

    .quiklnks li {
        display: inline-block;
        width: 45%;
        margin-right: 10px;
    }

    .ftr-social li {
        margin-right: 20px;
    }

    .field input,
    .field select,
    .field textarea {
        height: 40px;
    }

    .field textarea {
        height: 80px;
    }

    .field button {
        width: 100%;
        height: 40px;
        font-size: 14px;
    }

    .clientwrap ul {
        display: flex;
    }

    .dobox {
        transition: all 0.4s ease-In-out;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 0px 0px 35px 0px rgb(154 154 154 / 30%);
        width: 100%;
        height: auto;
        padding: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }

    .banershape {
        display: none;
    }

    .innerBanner {
        padding: 120px 0 30px 0;
        height: auto;
    }

    .innerBanner h2 {
        text-align: center;
        min-height: auto;
    }

    .innerBanner h6 {
        text-align: center;
        padding-bottom: 10px;
    }

    .abtsec1 {
        padding: 20px 0px;
    }

    .abtsecpad1 {
        padding-left: 0;
    }

    .abtsec1 a,
    .servsec6 a {
        width: 100%;
        height: 40px;
        font-size: 14px;
        line-height: 40px;
    }

    .abtsec1 p {
        font-size: 14px;
        line-height: 22px;
        padding-bottom: 10px;
    }

    .abtsec2 {
        padding-bottom: 30px;
    }

    .abtsec2 h4,
    .abtsec3 h4,
    .team h4,
    .contactdescp h4,
    .careersec1 h4,
    .careersec2 h4,
    .careersec3 h4,
    .testimonial h4 {
        font-size: 22px;
        letter-spacing: -0.5px;
        line-height: 28px;
        padding-bottom: 10px;
        padding-top: 20px;
    }

    .abtsec2 h4 span,
    .team h4 span {
        display: inline-block;
    }

    .abtsec2:after {
        height: 100%;
        width: 100%;
        top: 0;
        bottom: auto;
    }

    .abtsec2 p,
    .abtsec3 p,
    .team p,
    .contactdescp p,
    .careersec1 p,
    .clientsec p,
    .testibox p {
        font-size: 14px;
        line-height: 22px;
        padding: 0;
        padding-bottom: 10px;
    }

    .abtsec3 p {
        color: #c56bff;
    }

    .abtsec3 {
        padding: 10px 0px 30px;
    }

    .team {
        padding: 10px 0 30px;
    }

    .teamslider .slick-arrow {
        bottom: -20px;
    }

    .showsec1 {
        padding: 10px 0 30px;
    }

    .contactdescp {
        padding-bottom: 0px;
    }

    .showcase {
        padding: 0;
    }

    .tablist li {
        margin-right: 0;
        display: block;
        margin-bottom: 10px;
    }

    .tablist li a {
        width: 100%;
        font-size: 14px;
        padding: 10px;
    }

    .tablist li.first a {
        padding: 10px 0 5px;
    }

    .tablist {
        padding-bottom: 20px;
    }

    .showcase-slider .slick-arrow {
        bottom: -15px;
    }

    .careersec1,
    .careersec2 {
        padding: 10px 0 0;
    }

    .careersec1:after {
        display: none;
    }

    .careersec1 .container {
        padding-bottom: 10px;
    }

    section.careersec2 h4 {
        padding-bottom: 50px;
    }

    .carslider .slick-arrow {
        top: -40px;
    }

    .carbox {
        height: 250px;
        padding: 30px;
        text-align: center;
        justify-content: center;
    }

    .carbox img {
        left: -20px;
        right: 0;
        margin: auto;
    }

    .carbox h6 {
        font-size: 18px;
        text-align: center;
    }

    .careersec2 a {
        font-size: 16px;
        padding-bottom: 15px;
    }

    .careersec3 .clientwrap ul li h6 {
        font-size: 14px;
    }

    .careersec3 .clientwrap ul li span {
        font-size: 22px;
    }

    .careersec3 .clientwrap ul li span:before {
        font-size: 22px;
        line-height: 52px;
        right: -10px;
    }

    .clientsec {
        padding: 30px 0;
    }

    .testimonial .container:before,
    .clientsec:after,
    .contactdescp span {
        display: none;
    }

    .testimonial h4 {
        padding-bottom: 50px;
    }

    .testimonial {
        background-position: right;
        background-color: #00a985;
        height: auto;
        padding-top: 20px;
    }

    .testislider .slick-arrow {
        top: -40px;
    }

    .testislider .slick-arrow:after {
        font-size: 14px;
    }

    .testibox {
        padding: 20px;
        padding-right: 50px;
    }

    .testibox h5,
    .testibox h6 {
        font-size: 18px;
        padding-bottom: 10px;
    }

    .testibox:before {
        height: 25px;
        width: 31px;
        right: 20px;
        top: 26px;
    }

    .contact {
        padding: 30px 0;
    }

    .contactdescp h4 {
        padding-top: 5px;
    }

    .contactdescp p {
        margin: 0;
        padding-bottom: 20px;
    }

    .field2 {
        margin-bottom: 10px;
    }

    .field2 input,
    .field2 textarea {
        height: 45px;
        padding: 0 20px;
        font-size: 14px;
    }

    .field2 textarea {
        height: 100px;
    }

    .field2 button {
        width: 100%;
        height: 40px;
        font-size: 14px;
        line-height: 1;
    }

    .blogSection {
        padding: 30px 0 0px;
        background-size: 100% 100%;
    }

    .blog-detailSection {
        padding: 30px 0;
    }

    .blog-detailSection:after {
        top: 10px;
        left: auto;
        right: 20px;
        transform: rotate(270deg);
    }

    .blogdetailbox h6,
    .careersec3 h4 {
        font-size: 22px;
    }

    .blogdetailbox h6,
    .careersec3 h4 {
        font-size: 20px;
        letter-spacing: 0;
    }

    .blogdetailbox h4 {
        line-height: 1.2;
        font-size: 20px;
        letter-spacing: 0;
        padding-bottom: 10px;
    }

    .blogdetailbox span {
        letter-spacing: 0;
        padding: 5px 0 10px;
        font-size: 12px;
    }

    .bd-sociallist ul li {
        font-size: 8px;
    }

    .blogdetailbox a {
        font-size: 18px;
        margin-top: 20px;
    }

    .blogdetailbox p {
        font-size: 14px;
        padding-right: 0;
        line-height: 1.5;
        padding-bottom: 20px;
    }

    .blogdetailbox h5 {
        font-size: 20px;
        padding-top: 10px;
        padding-bottom: 5px;
    }

    .blogdetailbox a {
        font-size: 14px;
        line-height: 1;
        margin-top: 0;
    }

    .careersec3 h4 {
        font-size: 22px;
        letter-spacing: 0;
        line-height: 28px;
        padding-bottom: 10px;
        padding-top: 20px;
        text-align: center;
    }

    .blogdetailbox a {
        font-size: 14px;
        color: #d93458;
        font-weight: 600;
        line-height: 1;
        margin-top: 15px;
    }

    .bd-img img {
        width: 100%;
        height: auto;
    }

    .servsec6 {
        padding: 30px 0px;
    }

    .servsidesix:before {
        display: none;
    }

    .servsidesix {
        margin-top: 10px;
        padding: 20px;
    }

    .servsidesix h5,
    .servsidesix h6 {
        font-size: 16px;
        padding-bottom: 10px;
    }

    .servsec6con h4 {
        font-size: 20px;
        letter-spacing: 0;
        line-height: 1.3;
        padding-bottom: 10px;
    }

    .servsec6con p {
        font-size: 14px;
        line-height: 1.4;
        padding-bottom: 20px;
    }

    .mnservsec {
        padding: 30px 0px 0;
    }

    .mnservsec:before {
        display: none;
    }

    .mnservsec h4 {
        font-size: 22px;
        line-height: 1;
        padding-bottom: 10px;
    }

    .mnservsec p,
    .mnservsec1 p {
        font-size: 14px;
        line-height: 1.5;
        padding-bottom: 15px;
    }

    .mnservsec1 a {
        width: 150px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        margin-bottom: 15px;
    }

    .mnservsec1 {
        padding: 30px 0 0;
    }

    .mnservsec1 h4 {
        font-size: 22px;
        line-height: 1.3;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .mnservsec1 .col-md-6 .text-right {
        text-align: left !important;
    }

    .mnservsec1 .col-md-6 .text-right img {
        width: 100%;
        height: auto;
    }

    .mnservsec1 .container-fluid {
        padding-bottom: 20px;
    }

    .mnservsec p {
        font-size: 14px;
        line-height: 1.5;
        padding-bottom: 15px;
    }

    .servsec1 {
        padding: 30px 0 0;
    }

    .servsec7 h4,
    .servinsec h5,
    .servsec4 h4,
    .servsec5 h4,
    .servsec1 h4,
    .servsec2 h4,
    .servsec3 h4,
    .servsec1 h6 {
        font-size: 22px;
        letter-spacing: -0.5px;
        padding-bottom: 10px;
        line-height: 1.4;
    }

    .servsec7 p,
    .servinsec p,
    .servsec1 p {
        font-size: 14px;
        line-height: 1.7;
        padding-bottom: 15px;
    }

    .servsecshap,
    .servsec3:before,
    .servsec3:after {
        display: none;
    }

    .servsec2,
    .servsec3,
    .servsec7,
    .servsec4,
    .servsec5 {
        padding: 30px 0;
    }

    .servsec2 a {
        margin-bottom: 15px;
    }

    section.servsec3 {
        background: #000002;
        margin-top: 20px;
    }

    .servsec3 h4 {
        padding-top: 0;
    }

    .servsec3 h3 {
        font-size: 18px;
        padding-bottom: 10px;
    }

    section.servsec3 img,
    .servinsec h5:before,
    .servsec5 span {
        display: none;
    }

    .servsbx {
        height: 200px;
    }

    .servpb {
        padding-bottom: 30px;
    }

    .servinsec {
        padding: 0 0 30px;
    }

    .servsec4:before {
        left: 0;
        top: 0;
    }

    .servinsec a {
        width: 200px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        margin-bottom: 15px;
    }

    .clients-slider .slick-arrow,
    .slider-nav2 .slick-arrow {
        bottom: -10px;
    }

    .servsec5 ul li {
        margin: 0;
        margin-bottom: 10px;
    }

    .servsec5 ul li.last {
        margin: 0;
    }

    .servsec5 ul li a {
        width: 200px;
        height: 40px;
        font-size: 14px;
        line-height: 40px;
    }

    .servsbx h6,
    .servsbx h5 {
        font-size: 16px;
    }

    .servsec1 h6 {
        padding-left: 0;
    }

    .abtsec2 p {
        /* color: #c56bff; */
    }

    .abtsec2 h4 span {
        color: #c56bff;
    }

    .hmsec5 span {
        display: none;
    }

    input.wpcf7-form-control.wpcf7-submit {
        width: 200px !important;
        height: 50px !important;
        font-size: 14px !important;
        line-height: 1 !important;
    }

    .popupMain.mpop,
    .popupMain {
        width: 100%;
        height: 100vh;
        padding: 0px;
        z-index: 99999;
        min-height: auto;
        max-height: fit-content;
    }

    .popfield .wpcf7-list-item {
        display: block;
        width: auto;
    }

    #wpcf7-f157-o2 input.wpcf7-form-control.wpcf7-submit {
        width: 100% !important;
        margin: auto;
        display: block;
        font-size: 14px;
        height: 60px;
        line-height: 60px;
    }

    .pop-hd-wrp h3 {
        font-size: 22px;
        line-height: 1.4;
        letter-spacing: -1px;
        padding: 30px 0 10px;
    }

    .popfield input[type="text"],
    .popfield input[type="number"],
    .popfield input[type="email"] {
        height: 40px;
        font-size: 14px;
    }

    .popfield {
        margin-bottom: 15px;
    }

    .popfield ul li {
        width: 100%;
        margin-right: 0;
        padding-bottom: 0;
    }

    .popfield label {
        font-size: 14px;
        line-height: 1;
        display: flex;
        align-items: center;
    }

    .popfield input[type="checkbox"] {
        width: 15px;
        height: 15px;
    }

    .popfield h4 {
        font-size: 18px;
        line-height: 30px;
        padding-bottom: 10px;
        letter-spacing: -1px;
    }

    .popfield button {
        margin-top: 0;
        width: 100%;
        height: 50px;
        font-size: 14px;
    }

    .blogdetail-img.bd-social {
        height: 240px;
    }

    .popcarer,
    .popcarer1,
    .popcarer2,
    .popcarer3 {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        overflow-y: auto;
        padding: 50px 20px;
    }

    .popinner p {
        font-size: 14px;
        line-height: 1.5;
    }

    .popinner .wpcf7 input[type="file"] {
        padding-bottom: 30px;
    }

    .popinner #wpcf7-f234-o3 label,
    .slick-arrow:after {
        font-size: 14px;
    }

    .popinner input.wpcf7-form-control.wpcf7-text.wpcf7-email.wpcf7-validates-as-required.wpcf7-validates-as-email,
    .popinner input.wpcf7-form-control.wpcf7-text.wpcf7-validates-as-required {
        width: 100%;
        height: 39px;
        padding: 0 10px;
    }

    .srvlst li {
        font-size: 14px;
        line-height: 1.2;
    }

    ul.srvlst {
        padding-bottom: 20px;
    }

    .showcase-slider .slick-arrow:after {
        font-size: 14px;
    }

    .abtsec2:before,
    .abtsec2:after,
    .abtsec3:before,
    .dowrap a:before,
    .dowrap a:after,
    .mnservsec:after,
    .mnservsec2:before,
    .mnservsec3:before,
    .mnservsec2:after,
    .showsec1:before,
    .showsec1:after,
    .careersec1:before,
    .servsec4:before,
    .servsec4:after,
    .servsec1:before,
    .servsec2:before,
    .careersec2:before {
        display: none;
    }

    .servbxcon p {
        line-height: normal;
    }

    .popinner {
        padding: 20px;
    }

    .popfield input[type="text"],
    .popfield input[type="number"],
    .popfield input[type="email"] {
        padding: 0 10px;
    }

    .popserv {
        overflow: hidden;
        overflow-y: auto;
        width: 100%;
        height: 60%;
        padding: 50px 30px;
    }

    .popserv span {
        display: none;
    }

    .popserv h4 {
        font-size: 24px;
        padding-bottom: 20px;
    }

    .popserv ul li a {
        width: 100%;
        height: 60px;
        font-size: 16px;
        line-height: 60px;
    }

    .popserv ul li {
        display: block;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .our-clients-sec {
        padding: 40px;
    }

    .our-clients-sec .slick-arrow {
        height: 40px;
        width: 40px;
    }

    .our-clients-sec .slick-arrow {
        left: -50px;
    }

    .our-clients-sec .slick-arrow.next-arrow {
        right: -50px;
    }




}

.blogdetailbox ul li strong {
    font-weight: 600;
}

.blogdetailbox ol li {
    list-style: number;
    line-height: 29pxx;
    line-height: 23px;
    width: 86%;
    margin: auto;
    /* font-weight: 900; */
}

.mapimg img {
    display: none;
}


#triangle-bottomleft iframe {
    width: 840px;
    position: relative;
    left: -320px;
    top: -100px;
    height: 680px;

}

#triangle-bottomleft {
    max-height: 580px;
    max-width: 100%;
    clip-path: polygon(100% 0%, 0% 0%, 0% 100%);
}

.our-clients-sec .img-box {
    overflow: hidden;
    /*  cursor: url(https://insitech.ae/wp-content/uploads/2022/09/Drag-1.png), auto; */
}


.our-clients-sec .box {
    position: relative;
    padding-bottom: 50px;
    transition-delay: .7s;
}

.our-clients-sec .box img {
    transition: 1s;

    filter: grayscale(100%);
}

.our-clients-sec .slick-slide.slick-center .img-box img {
    transition: 1.5s;
    transition-delay: .7s;
}

/* 
.our-clients-sec .box:hover .img-box img{
        transform:scale(1.15);
         filter: grayscale(0%);
    } */

.our-clients-sec .slick-slide {
    /* transform: scale(0.92); */
    margin: 0 25px;
    transition: .5s;
}

.our-clients-sec .slick-slide.slick-center {
    transform: scale(1.11);
    /* transition:1s; */
    transition-duration: 1s;
    transition-delay: .4s;
}

.our-clients-sec .slick-slide.slick-center .box img {
    /* transform:scale(1.15); */
    filter: grayscale(0%);

}

.our-clients-sec .slick-track {
    padding: 50px 0;
}


.our-clients-sec .logo {
    margin: 20px 0;
}

.our-clients-sec .arrow img {
    width: 60px;
    position: absolute;
    left: -150px;
    bottom: -150px;
    transition: 1s;
    opacity: 0;
}

.our-clients-sec .slick-slide.slick-center .arrow img {


    left: 0px;
    bottom: 0px;

    opacity: 1;
}

.dowrap:hover .dobox .our-clients-sec .slick-list.draggable {
    /* padding-left:8%; */
}

.logos-slider img {
    max-width: 278px;
    height: 170px;
    object-fit: scale-down;
}
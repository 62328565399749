h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

img {
    max-width: 100%;
}

body {
    padding: 0;
    margin: 0;
    /* cursor: url(../images/cursor.png), none !important; */
    background-color: #202020;
}

a {
    /* cursor: url(../images/cursor.png), none !important; */
    z-index: 99999;
}

.home .b-img {
    background-image: url(../images/website-banner2.jpg);
    height: 100vh;
    background-size: cover;
    background-position: top;
}

.home .section1 {
    height: 100%;
    display: flex;
    align-items: center;
}

/* .home .section1 .container {
    /* padding-top: 0; */

.sideDrawer {
    display: none !important;
}

.sideDrawer .menuIcon {
    width: 30px !important;
}

.sideDrawer .logo img {
    width: 100% !important;
}

.sideDrawer .menu {
    width: 0vw;
    padding: 0;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #202020;
    z-index: 999;
    transition: 0.7s;
}

.sideDrawer .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 50px 0;
}

.sideDrawer .menu li {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    text-align: center !important;
    margin-bottom: 20px !important;
    font-size: 18px !important;
}

.sideDrawer .menu li .closeIcon {
    margin: auto;
    margin-bottom: 30px;
    width: 40px;
    height: 40px;
    background-color: red;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: 24px;
}

.sideDrawer .menu li .closeIcon img {
    width: 50% !important;
}

.sideDrawer .menu li a {
    font-size: 24px !important;
}

.sideDrawer .menuOpen {
    width: 100vw;
}

.sideDrawer .sideLogo {
    margin-top: 30px;
}

.section1 span {
    display: inline-block;
    font-size: 58px;
    font-weight: 700;
    color: #fff;
    line-height: 70px;
    width: 85%;
}

.home .section2 {
    /* height: 450px; */
    /* background: url(../images/pexels-luciann-photography-3971351-3840x2160-25fps.mp4); */
    position: relative;
    padding: 50px;
    padding-bottom: 100px;
    background: #00000085;
}

#background-video-section2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}

.home .section2 .process-div {
    padding: 50px 30px;
    border: 2px solid #c083cb;
    box-shadow: 2px 2px 10px 2px #c083cb;
    height: 320px;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 99;
    position: relative;
}

.home .section2 .head h1 {
    margin-bottom: 100px;
    font-size: 83px;
}

.home .section2 .process-div .heading h2 {
    position: relative;
    padding-bottom: 30px;
    font-size: 33px;
}

.home .section2 .process-div .heading h2::after {
    content: "";
    width: 50px;
    height: 3px;
    background: white;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
}

.home .section2 .container-fluid .row {
    position: relative;
    overflow: hidden;
}

.home .section2 .container-fluid .row .hoverDiv {
    position: absolute;
    height: 100%;
    background-color: #9b5fc2;
    z-index: 2;
    bottom: 0;
    width: calc(25% - 35px);
    display: none;
}



.section4 {
    margin: 50px 0;
}

.home .section2 .process-div .text-div p {
    margin-top: 30px;
    font-size: 14px;
}

.section5 .grid-layout {
    height: 660px;
}

.section5 .head h1 {
    font-size: 175px;
    margin-bottom: 20px;
    font-weight: 700;
}

.section5 .head h1 span {
    font-size: 52px;
    font-weight: 300;
}


.section5 .grid-layout .pic {
    width: 100%;
    height: calc(100% - 30px);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.3s;
}

.section5 .grid-layout a {
    width: 100%;
    height: calc(100% - 30px);
}

.section5 .grid-layout .pic:hover {
    background-size: 120%;
}

.section5 .grid-layout .pic-1 {
    background-image: url(../images/port.png);
}

.section5 .grid-layout .pic-2 {
    background-image: url(../images/SocialMedia01.jpg);
}

.section5 .grid-layout .pic-3 {
    background-image: url(../images/SocialMedia02.jpg);
}

.section5 .grid-layout .pic-4 {
    background-image: url(../images/greenligghtsolar.png);
    background-size: 100%;
    background-position: top;
}

.section5 .grid-layout .pic-5 {
    background-image: url(../images/Canizaleze.png);
    background-size: 100%;
    background-position: top;
}

.section6 {
    padding: 50px 0;
    padding-bottom: 150px;
    position: relative;
    background-color: #202020;
}

.section6::after {
    content: "";
    background-image: url(../images/contact-us.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    position: absolute;
    width: 970px;
    bottom: 0;
    height: 770px;
    left: -100px;
    z-index: 1;
}

.section6 .head h1 {
    font-size: 47px;
    color: #9b5fc2;
}

.section6 .head h2 {
    font-size: 46px;
    color: #fff;
    font-weight: 600;
}

.section6 .text-div p {
    width: 72%;
    margin: auto;
    margin-top: 10px;
}

.section6 .form-div {
    margin-top: 50px;
}

.section6 .form-div .input-div {
    margin-bottom: 30px;
}

.section6 .form-div .input-div input {
    border: 2px solid #9172d1;
    width: 100%;
    width: 72%;
    height: 80px;
    padding: 20px;
    background: transparent;
    font-size: 20px;
    color: white;
}

.section6 .form-div .input-div textarea {
    border: 2px solid #9172d1;
    width: 100%;
    width: 72%;
    padding: 20px;
    background: transparent;
    font-size: 20px;
    color: white;
}

.section6 .btn-div button {
    height: 65px;
    width: 170px;
    background-color: #9b5fc2;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    font-weight: 600;
}

.section6 .toastDiv {
    width: 72%;
    margin: auto;
    margin-top: 10px;
    visibility: hidden;
}

header {
    background-color: transparent;
}

.services .b-img {
    background-image: url(../images/bg-services.jpg);
    height: 80vh;
    background-size: cover;
    background-position: top;
}

.services .b-img .section1 {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.services .b-img .section1 h2 {
    font-size: 58px;
    font-weight: 700;
    color: #fff;
    line-height: 70px;
}

.services .mnservsec1 .icon-div img {
    width: 100px;
}

.serviceSingle .bg-img {
    background-image: url(../images/bg-services.jpg);
    height: 80vh;
    background-size: cover;
    background-position: top;
}

.serviceSingle .bg-img .section1 {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.serviceSingle .bg-img .section1 h2 {
    font-size: 58px;
    font-weight: 700;
    color: #fff;
    line-height: 70px;
}

.serviceSingle .bg-img .section1 h6 {
    color: #fff;
}

button,
a {
    border-radius: 5px !important;
}

.headbtns li a {
    border-radius: 0 !important;
    width: 300px !important;
}

.headbtns li.last a {
    border-radius: 0 !important;
    width: 270px !important;
}

.portfolio .section2 {
    padding: 80px 0
}

.portfolio .section2 .nav-tabs {
    border: none;
    justify-content: space-around;
}

.portfolio .section2 .nav-tabs a {
    width: 300px;
    text-align: center;
    height: 40px;
    background-color: #9172d1;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffff;
    font-weight: bold;
    z-index: 9;
}

.portfolio .section2 .nav-tabs a.active {
    background-color: #fff;
    color: #9172d1;
}

.portfolio .section2 .nav-tabs a:hover {
    background-color: #fff;
    color: #9172d1;
}

.portfolio .section2 .tab-content>.tab-pane {
    padding-top: 50px;
}

.hmsec2 .slick-next {
    right: -10px !important;
    z-index: 99;
    top: 40%;
    height: 50px;
    width: 50px;
}

.hmsec2 .slick-prev {
    left: -10px !important;
    z-index: 99;
    top: 40%;
    height: 50px;
    width: 50px;
}

.slick-prev:before,
.slick-next:before {
    font-size: 48px;
}

@media (max-width: 1200px) {

    .section1 span,
    .b-img .section1 h2,
    .bg-img .section1 h2 {
        font-size: 38px !important;
        line-height: 50px !important;
        width: 75% !important;
    }

    .home .section2 .head h1 {
        font-size: 40px;
        margin-bottom: 50px;
    }

    .home .section2 .process-div {
        padding: 20px;
        height: auto;
        z-index: 0;
    }

    .home .section2 .process-div .heading h2 {
        font-size: 24px;
        padding-bottom: 20px;
    }

    .home .section2 .process-div .text-div p {
        margin-top: 20px;
    }

    .hmsec2 {
        padding: 100px 0 !important;
        padding-bottom: 50px !important;
    }

    .hmsec2 p {
        font-size: 16px !important;
    }

    .dobox {
        height: auto !important;
        padding: 50px 0 !important;
        margin-bottom: 10px !important;
    }

    .section5 .head h1 {
        font-size: 100px;
    }

    .section5 .head h1 span {
        font-size: 30px;
    }

    .section5 .grid-layout .pic {
        background-size: cover;
    }

    .section6::after {
        width: 800px;
        height: 630px;
    }

    .section6 .form-div .input-div input {
        font-size: 16px;
        height: 50px;
        padding: 0 20px;
    }

    .section6 .form-div .input-div textarea {
        font-size: 16px;
        padding: 20px;
    }

    .section6 .btn-div button {
        height: 50px;
        font-size: 16px;
    }
}

@media (max-width: 992px) {
    .header-top {
        display: none !important;
    }


    .section5 .grid-layout .pic-1 {
        background-image: url('../images/NYC.jpg');
        background-size: contain !important;
        background-color: #fecb33;
    }


    .section1 .container {
        padding-top: 0;
    }

    .home .section2 .process-div {
        margin-bottom: 20px;
    }

    .hmsec2 {
        padding-bottom: 0 !important;
    }

    .section4 {
        margin-top: 0;
    }

    .portfolio .section2 .nav-tabs a {
        width: 100%;
        margin-bottom: 10px;
    }
}

@media (max-width: 768px) {
    .menu {
        display: none !important;
    }

    .sideDrawer {
        display: inline-block !important;
        width: 100% !important;

    }

    .sideDrawer .menu {
        display: flex !important;
    }

    .main-header img {
        width: 200px;
    }

    .home .b-img {
        height: 400px;
    }

    .section1 span,
    .b-img .section1 h2,
    .bg-img .section1 h2 {
        width: 100% !important;
        text-align: center !important;
        font-size: 30px !important;
        line-height: 40px !important;
    }

    .b-img .section1 h6,
    .bg-img .section1 h6 {
        text-align: center;
    }

    .home .section2 .head h1 {
        font-size: 28px;
    }

    .home .section2 .container-fluid .row .hoverDiv {
        display: none !important;
    }

    .home .section2 .container-fluid .row {
        justify-content: center;
    }

    .section5 .head h1 {
        font-size: 50px;
    }

    .section5 .head h1 span {
        font-size: 18px;
    }

    .section5 .grid-layout .pic {
        background-size: cover !important;
        height: 200px;
    }

    .section6 {
        padding-top: 20px;
        z-index: 0;
    }

    .section6 .head h1 {
        font-size: 28px;
    }

    .section6 .head h2 {
        font-size: 28px;
    }

    .section6 .text-div p {
        font-size: 16px;
    }


    .services .mnservsec1 .icon-div {
        text-align: center;
    }

    .services .mnservsec1 .reverse {
        flex-direction: column-reverse;
    }

    .section5 .grid-layout .pic-1 {
        background-image: url('../images/NYC.jpg');
        background-size: contain !important;
        background-color: #fecb33;
    }

}

@media (max-width: 600px) {

    .section1 span,
    .b-img .section1 h2,
    .bg-img .section1 h2 {
        font-size: 22px !important;
        line-height: 32px !important;
    }

    .home .section2 {
        padding: 30px 0;
    }

    .home .section2 .head h1 {
        font-size: 22px;
        margin-bottom: 30px;
    }

    .home .section2 .process-div .heading h2 {
        font-size: 18px;
    }

    .home .section2 .process-div .text-div p {
        font-size: 14px;
    }

    .hmsec2 {
        padding: 30px 0 !important;
        text-align: center;
    }

    .hmsec2 p {
        font-size: 14px !important;
    }

    .hmsec2 .slick-slide {
        padding-top: 0;
    }

    .section5 .head h1 {
        font-size: 40px;
    }

    .section5 .head h1 span {
        font-size: 16px;
        margin-left: 5px;
        display: inline-block;
    }

    .section5 .grid-layout {
        height: auto;
    }

    .section5 .grid-layout .pic {
        margin-bottom: 10px;
    }

    .section6 .text-div p {
        font-size: 14px;
    }

    .section6::after {
        width: 600px;
        height: 470px;
    }

    .section6 {
        padding-bottom: 50px;
    }

    .services {
        text-align: center;
    }

}

.ftr-social {
    padding-left: 0 !important;
}


@media (min-width:767px) {

    .calendly-badge-widget {
        position: absolute !important;
        top: 0;
        right: 0 !important;
        height: 55px;
    }

    .calendly-badge-content {
        height: 55px !important;
        width: 270px !important;
        border-radius: 0 !important;
        opacity: 0;
    }
}

@media (max-width:767px) {
    .calendly-badge-widget {
        display: none;
    }

    .abtsec1 h4 {
        font-size: 32px !important;
        text-align: center;
    }

    .abtsec1 p,
    .abtsec2 p,
    .abtsec2 h4,
    .servsec6con h4,
    .servsec6con p {
        text-align: center;
    }

    .servsec6 .row.align-items-center {
        flex-direction: column-reverse;
    }

    .servsec6con {
        margin-top: 20px;
    }
}

.whatsappIcon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    z-index: 9999;
}